import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Button, Media, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./SingleVideoSec.css";
import { fetchModelSinglePostStart } from "../../../store/actions/ModelAction";
import VideoPlayer from "react-video-js-player";
import StarRatings from "react-star-ratings";
import { getSuccessNotificationMessage } from "../../Helper/NotificationMessage";
import { createNotification } from "react-redux-notify";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ReactPlayer from 'react-player'

const SingleVideoSecIndex = (props) => {
  useEffect(() => {
    props.dispatch(
      fetchModelSinglePostStart({
        post_unique_id: props.match.params.post_unique_id,
      })
    );
  }, []);

  const [isVisible, setIsVisible] = useState(false);

  const onCopy = (event) => {
    const notificationMessage = getSuccessNotificationMessage(
      "Profile Link Copied!!"
    );
    props.dispatch(createNotification(notificationMessage));
  };

  const downloadFile = (e) => {
    console.log(e.target.href);
    fetch(e.target.href, {
      method: "GET",
      headers: {},
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "image.png"); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div className="main-wrapper">
        <div className="personal-bio-sec">
          {props.modelSinglePost.loading ? (
            "Loading..."
          ) : (
            <Container>
              <Row>
                <Col md={12}>
                  <div className="personal-bio-header">
                    <Link
                      className="back-btn"
                      to={`/view/` + props.modelSinglePost.data.post.model.username}
                    >
                      <i class="fas fa-chevron-left mr-2"></i>Back
                    </Link>
                    <Button className="btn-fans-club" hidden>
                      Fan Club
                    </Button>
                  </div>
                  <div className="personal-bio-card">
                    <div className="video-container-left">
                      {props.modelSinglePost.data.post.file_type == "video" ? (
                        // <VideoPlayer
                        //   controls={true}
                        //   src={props.modelSinglePost.data.post.file}
                        //   poster={props.modelSinglePost.data.post.preview_image}
                        //   width="100%"
                        //   height="336px"
                        //   className="video-size"
                        //   // autoplay={true}
                        //   // hideControls={[
                        //   //   "play",
                        //   //   "volume",
                        //   //   "seekbar",
                        //   //   "timer",
                        //   //   "playbackrates",
                        //   //   "fullscreen",
                        //   // ]}
                        // />
                        <ReactPlayer
                          playIcon={true}
                          width="100%"
                          height="336px"
                          className="video-size"
                          controls={true}
                          url={props.modelSinglePost.data.post.file}
                          poster={props.modelSinglePost.data.post.preview_image}

                        />


                      ) : (
                        <Image
                          src={props.modelSinglePost.data.post.file}
                          alt="user-image"
                          className="categories-img"
                        />
                      )}
                    </div>
                    <div className="video-container-right">
                      <div className="personal-bio-profile-sec">
                        <div className="profile-container">
                          <div className="profile-img-sec">
                            <Image
                              src={
                                props.modelSinglePost.data.post.model.picture
                              }
                              alt="user-image"
                              className="user-img"
                            />
                          </div>
                          <Link to="#">
                            <h3 className="title">
                              {props.modelSinglePost.data.post.model.name}
                            </h3>
                            <p className="desc">
                              {
                                props.modelSinglePost.data.post.model
                                  .category_name
                              }{" "}
                              -{" "}
                              {
                                props.modelSinglePost.data.post.model
                                  .subcategory_name
                              }
                            </p>
                          </Link>
                        </div>
                        <Button className="btn-book">
                          <Link
                            to={
                              `/view/` +
                              props.modelSinglePost.data.post.model.username
                            }
                          >
                            Book
                          </Link>
                        </Button>
                      </div>
                      <div className="profile-action-sec">
                        <ul className="list-unstyled action-sec">
                          <li>
                            <Link>
                              <Image
                                src={
                                  window.location.origin +
                                  "/assets/images/link.svg"
                                }
                                alt="user-image"
                                className="action-icon"
                              />
                              <CopyToClipboard
                                text={window.location.href}
                                onCopy={onCopy}
                              >
                                <p className="action-desc">Copy link</p>
                              </CopyToClipboard>
                            </Link>
                          </li>
                          {localStorage.getItem("userId") ?
                            <li>
                              <a
                                href={props.modelSinglePost.data.post.file}
                                download
                                target="_blank"
                                onClick={downloadFile}
                                className="btn-none"
                              >
                                <Image
                                  src={
                                    window.location.origin +
                                    "/assets/images/download.svg"
                                  }
                                  alt="user-image"
                                  className="action-icon"
                                />
                                <p className="action-desc">Download</p>
                              </a>
                            </li>
                            : null
                          }
                          <li>
                            <a
                              target="_blank"
                              href={
                                props.modelSinglePost.data.post.model
                                  .facebook_link
                                  ? props.modelSinglePost.data.post.model
                                    .facebook_link
                                  : "#"
                              }
                            >
                              <Image
                                src={
                                  window.location.origin +
                                  "/assets/images/facebook.svg"
                                }
                                alt="user-image"
                                className="action-icon"
                              />
                              <p className="action-desc">Facebook</p>
                            </a>
                          </li>
                          <li>
                            <a
                              target="_blank"
                              href={
                                props.modelSinglePost.data.post.model
                                  .twitter_link
                                  ? props.modelSinglePost.data.post.model
                                    .twitter_link
                                  : "#"
                              }
                            >
                              <Image
                                src={
                                  window.location.origin +
                                  "/assets/images/twitter.svg"
                                }
                                alt="user-image"
                                className="action-icon"
                              />
                              <p className="action-desc">Twitter</p>
                            </a>
                          </li>
                        </ul>
                      </div>
                      {props.modelSinglePost.data.post.post_review &&
                        props.modelSinglePost.data.post.post_review.length > 0 ? (
                        <div className="personal-review-top-border">
                          <div className="personal-review-sec-header">
                            <div
                              className="personal-review-btn"
                              onClick={() => setIsVisible(!isVisible)}
                            >
                              <p className="toggle-head">View Review</p>
                              <Image
                                src={
                                  window.location.origin +
                                  "/assets/images/cher-arrow.svg"
                                }
                                alt="user-image"
                                className="personal-bio-icon"
                              />
                            </div>
                          </div>
                          {isVisible && (
                            <div>
                              <div className="personal-review-sec-header">
                                <div className="personal-review-btn">
                                  <p className="toggle-head">Hide review</p>
                                  <Image
                                    src={
                                      window.location.origin +
                                      "/assets/images/cher-arrow.svg"
                                    }
                                    alt="user-image"
                                    className="personal-bio-icon-opposite"
                                  />
                                </div>
                              </div>
                              <div className="rating-card-sec">
                                <ul className="list-unstyled rating-sec">
                                  <Media as="li">
                                    <i class="fas fa-star"></i>
                                  </Media>
                                  <Media as="li">
                                    <i class="fas fa-star"></i>
                                  </Media>
                                  <Media as="li">
                                    <i class="fas fa-star"></i>
                                  </Media>
                                  <Media as="li">
                                    <i class="fas fa-star"></i>
                                  </Media>
                                  <Media as="li">
                                    <i class="fas fa-star"></i>
                                  </Media>
                                </ul>
                              </div>
                              <p className="bio-note">
                                There is currently no review available.
                              </p>
                            </div>
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  modelSinglePost: state.model.modelSinglePost,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(SingleVideoSecIndex);
