import React, { useState, useEffect } from 'react';
import {
  PaymentElement,
  LinkAuthenticationElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import { Form, Modal, Col, Row, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useTranslation } from 'react-multi-lang';
import { useHistory } from 'react-router-dom';
import { videoCallsPaymentStart } from '../../store/actions/VideoCall';
import { fetchVideocallUserStart } from "../../store/actions/VideoCall";

const VideoCallPaymentSec = (props) => {
  const t = useTranslation("add_money");

  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();

  // const [email, setEmail] = useState('');
  const [skipRender, setSkipRender] = useState(true);
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = props.clientSecret;

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage(t("payment_succeeded"));
          break;
        case "processing":
          setMessage(t("payment_processing"));
          break;
        case "requires_payment_method":
          setMessage(t("payment_failed"));
          break;
        default:
          setMessage("");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const { error, ...response } = await stripe.confirmPayment({
      elements,
      redirect: 'if_required',
      confirmParams: {
      },
    });
    if (!error) {
      props.dispatch(
        videoCallsPaymentStart({
          video_call_id: props.videoCallId,
          payment_id: response.paymentIntent.id,
        })
      );
    }
    if (error)
      if (error.type === "card_error" || error.type === "validation_error") {
        setMessage(error.message);
      } else {
        setMessage("An unexpected error occurred.");
      }
    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs"
  }

  useEffect(() => {
    if (!skipRender && !props.videoCallsPayment.loading &&
      Object.keys(props.videoCallsPayment.data).length > 0) {
      props.dispatch(fetchVideocallUserStart());
    }
    setSkipRender(false);
  }, [props.videoCallsPayment]);


  return (
    <>
      <Form className="mt-3">
        <LinkAuthenticationElement
          id="link-authentication-element"
          className="mb-2"
        />
        <PaymentElement id="payment-element" options={paymentElementOptions} />
        <div className="d-flex justify-content-center mt-5">
          <Button
            disabled={isLoading || !stripe || !elements || props.videoCallsPayment.buttonDisable}
            id="submit"
            type="submit"
            className="default-btn pay-now"
            onClick={handleSubmit}
          >
            <span id="button-text">
              {isLoading || props.videoCallsPayment.buttonDisable ? t("processing") : t("pay_now")}
            </span>
          </Button>
        </div>
      </Form>
    </>
  );

}


const mapStateToPros = (state) => ({
  videoCallsPayment: state.videocall.videoCallsPayment,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(VideoCallPaymentSec);