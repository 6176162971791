import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { t, withTranslation } from "react-multi-lang";
import configuration from "react-global-configuration";

const FilterSec = (props) => {
  return (
    <>
      <div className="filter-category-select-sec-1">
        <h4>{t("sort_by")}</h4>
        <Form>
          <Form.Check
            type="radio"
            id="2"
            label={t("price_high_low")}
            name="sort_by"
            value="price_hl"
            onChange={() => { }}
            onClick={props.filterOption}
            checked={
              props.filterInputData.sort_by === "price_hl" ? true : false
            }
            custom
          />
          <Form.Check
            type="radio"
            id="3"
            label={t("price_low_high")}
            name="sort_by"
            value="price_lh"
            onChange={() => { }}
            onClick={props.filterOption}
            checked={
              props.filterInputData.sort_by === "price_lh" ? true : false
            }
            custom
          />
          <Form.Check
            type="radio"
            id="4"
            label={t("no_of_reviews")}
            name="sort_by"
            value="reviews"
            onChange={() => { }}
            onClick={props.filterOption}
            checked={
              props.filterInputData.sort_by === "reviews" ? true : false
            }
            custom
          />
          <Form.Check
            type="radio"
            id="5"
            label={t("newest")}
            name="sort_by"
            value="new"
            onChange={() => { }}
            onClick={props.filterOption}
            checked={
              props.filterInputData.sort_by === "new" ? true : false
            }
            custom
          />
        </Form>
      </div>

      <div className="filter-category-select-sec">
        <h4>{t("price")}</h4>
        <Form>
          <Form.Check
            type="radio"
            id="6"
            label={`${configuration.get("configData.currency")}0 - ${configuration.get("configData.currency")}100`}
            name="max_price"
            value="0-100"
            onChange={() => { }}
            onClick={props.filterOption}
            checked={
              props.filterInputData.max_price === "0-100" ? true : false
            }
            custom
          />
          <Form.Check
            type="radio"
            id="7"
            label={`${configuration.get("configData.currency")}100 - ${configuration.get("configData.currency")}200`}
            name="max_price"
            value="100-200"
            onChange={() => { }}
            onClick={props.filterOption}
            checked={
              props.filterInputData.max_price === "100-200" ? true : false
            }
            custom
          />
          <Form.Check
            type="radio"
            id="8"
            label={`${configuration.get("configData.currency")}200 - ${configuration.get("configData.currency")}300`}
            name="max_price"
            value="200-300"
            onChange={() => { }}
            onClick={props.filterOption}
            checked={
              props.filterInputData.max_price === "200-300" ? true : false
            }
            custom
          />
          <Form.Check
            type="radio"
            id="9"
            label={`${configuration.get("configData.currency")}300 - ${configuration.get("configData.currency")}400`}
            name="max_price"
            value="300-400"
            onChange={() => { }}
            onClick={props.filterOption}
            checked={
              props.filterInputData.max_price === "300-400" ? true : false
            }
            custom
          />
          <Form.Check
            type="radio"
            id="10"
            label={`${configuration.get("configData.currency")}500+`}
            name="max_price"
            value="500"
            onChange={() => { }}
            onClick={props.filterOption}
            checked={
              props.filterInputData.max_price === "500" ? true : false
            }
            custom
          />
        </Form>
      </div>

      <div className="filter-category-select-sec">
        <h4>{t("average_review_rating")}</h4>
        <Form>
          <Form.Check
            type="radio"
            id="11"
            label="4 stars & up"
            name="rating"
            value="4"
            onChange={() => { }}
            onClick={props.filterOption}
            checked={props.filterInputData.rating === "4" ? true : false}
            custom
          />
          <Form.Check
            type="radio"
            id="12"
            label="3 stars & up"
            name="rating"
            value="3"
            onChange={() => { }}
            onClick={props.filterOption}
            checked={props.filterInputData.rating === "3" ? true : false}
            custom
          />
          <Form.Check
            type="radio"
            id="13"
            label="2 stars & up"
            name="rating"
            value="2"
            onChange={() => { }}
            onClick={props.filterOption}
            checked={props.filterInputData.rating === "2" ? true : false}
            custom
          />
          <Form.Check
            type="radio"
            id="14"
            label="1 stars & up"
            name="rating"
            value="1"
            onChange={() => { }}
            onClick={props.filterOption}
            checked={props.filterInputData.rating === "1" ? true : false}
            custom
          />
        </Form>
      </div>

      <div className="filter-category-select-sec">
        <h4>{t("number_of_reviews")}</h4>
        <Form>
          <Form.Check
            type="radio"
            id="15"
            label="1000+"
            name="no_of_reviews"
            value="1000"
            onChange={() => { }}
            onClick={props.filterOption}
            checked={
              props.filterInputData.no_of_reviews === "1000" ? true : false
            }
            custom
          />
          <Form.Check
            type="radio"
            id="16"
            label="500+"
            name="no_of_reviews"
            value="500"
            onChange={() => { }}
            onClick={props.filterOption}
            checked={
              props.filterInputData.no_of_reviews === "500" ? true : false
            }
            custom
          />
          <Form.Check
            type="radio"
            id="17"
            label="200+"
            name="no_of_reviews"
            value="200"
            onChange={() => { }}
            onClick={props.filterOption}
            checked={
              props.filterInputData.no_of_reviews === "200" ? true : false
            }
            custom
          />
          <Form.Check
            type="radio"
            id="18"
            label="100+"
            name="no_of_reviews"
            value="100"
            onChange={() => { }}
            onClick={props.filterOption}
            checked={
              props.filterInputData.no_of_reviews === "100" ? true : false
            }
            custom
          />
          <Form.Check
            type="radio"
            id="19"
            label="50+"
            name="no_of_reviews"
            value="50"
            onChange={() => { }}
            onClick={props.filterOption}
            checked={
              props.filterInputData.no_of_reviews === "50" ? true : false
            }
            custom
          />
          <Form.Check
            type="radio"
            id="20"
            label="25+"
            name="no_of_reviews"
            value="25"
            onChange={() => { }}
            onClick={props.filterOption}
            checked={
              props.filterInputData.no_of_reviews === "25" ? true : false
            }
            custom
          />
        </Form>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(FilterSec));
