import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import NoDataFound from '../NoDataFound/NoDataFound';
import InfiniteScroll from 'react-infinite-scroll-component';
import MyTicketsTableRow from './MyTicketsTableRow';
import TableLoader from '../Handlers/Loaders/TableLoader';
import VideoCallDetailsModal from '../Helper/VideoCallDetailsModal';
import { Link } from 'react-router-dom';
import { t, withTranslation } from "react-multi-lang";
import configuration from "react-global-configuration";

const MyTicketsTable = (props) => {

  const [selectedVideoCall, setSelectedVideoCall] = useState(null);

  const closeVideoCallDetailsModal = () => {
    setSelectedVideoCall(null);
  }

  return (
    <>
      {props.videoCall.loading ?
        <TableLoader />
        : props.videoCall.data.video_calls && props.videoCall.data.video_calls.length > 0 ?
          <div className="custom-table-list">
            <div className="custom-table-header">
              <div className="custom-table-header-card">
                <h5>{t("image")}</h5>
                <h5>{props.isSent ? t("model") : t("user")}</h5>
                <h5>Scheduled Date</h5>
                {props.isSent ? <h5>{t("ticket")}</h5> : null}
                <h5>{t("paid_amount")}</h5>
                <h5>{t("status")}</h5>
                <h5>{t("action")}</h5>
              </div>
            </div>
            <div className="custom-table-body">
              <InfiniteScroll
                dataLength={props.videoCall.data.video_calls.length}
                next={props.fetchMoreData}
                hasMore={props.videoCall.data.video_calls.length < props.videoCall.data.total_video_calls &&
                  props.videoCall.errorCount < 2}
                loader={<TableLoader />}
              >
                {props.videoCall.data.video_calls.map((videoCall, index) =>
                  <MyTicketsTableRow
                  index={index}
                    videoCall={videoCall}
                    isSent={props.isSent}
                    isAction={props.isAction}
                    setSelectedVideoCall={setSelectedVideoCall}
                  />
                )}
              </InfiniteScroll>
            </div>
          </div>
          : props.isSent ?
            <div className="text-white mt-5">
              <h3>{t("no_events")}</h3>
              <p className="mt-4">{t("no_events_note")} {("")}
               {configuration.get("configData.site_name")} {t("live_events")}
              </p>
              <Link to="/events" className="enroll-btn mt-5 btn btn-success">{t("discover_live_events")}</Link>
            </div>
            : <NoDataFound />
      }
      {selectedVideoCall ?
        <VideoCallDetailsModal
          videoCall={selectedVideoCall}
          closeVideoCallDetailsModal={closeVideoCallDetailsModal}
          isSent={props.isSent}
        />
        : null
      }
    </>
  );
}

const mapStateToPros = (state) => ({
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(withTranslation(MyTicketsTable));