import React, { useState, useEffect, useLayoutEffect } from "react";
import { connect } from "react-redux";
import "./Account.css";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import {
  editUserDetails,
  fetchUserDetailsStart,
  updateUserDetailsStart,
  usernameAvailablityStart,
} from "../../store/actions/UserAction";
import { t, withTranslation } from "react-multi-lang";

const EditProfile = (props) => {
  useEffect(() => {
    if (props.profile.loading) props.dispatch(fetchUserDetailsStart());
  }, []);

  const [profileInputData, setProfileInputData] = useState({
    username: "",
  });

  const [image, setImage] = useState({
    picture: "",
    cover: "",
  });

  const handleChangeImage = (event) => {
    if (event.currentTarget.type === "file") {
      setProfileInputData({
        ...profileInputData,
        [event.currentTarget.name]: event.currentTarget.files[0],
      });
      let reader = new FileReader();
      let file = event.currentTarget.files[0];

      if (event.currentTarget.name === "cover") {
        reader.onloadend = () => {
          setImage({ ...image, cover: reader.result });
        };
      }

      if (event.currentTarget.name === "picture") {
        reader.onloadend = () => {
          setImage({ ...image, picture: reader.result });
        };
      }

      if (file) {
        reader.readAsDataURL(file);
      }
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (Object.keys(profileInputData).length > 0)
      props.dispatch(updateUserDetailsStart(profileInputData));
    else props.dispatch(updateUserDetailsStart());
  };

  const usernameCheck = (event) => {
    setProfileInputData({
      ...profileInputData,
      username: event.currentTarget.value,
    });
    if (profileInputData.username.length > 4) {
      props.dispatch(
        editUserDetails(event.currentTarget.name, event.currentTarget.value)
      );
      props.dispatch(
        usernameAvailablityStart({
          username: event.currentTarget.value,
        })
      );
    }
  };

  const { pathname } = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <div className="main-wrapper">
        <div className="edit-profile-sec">
          <div className="edit-profile-card">
            <h3 className="title">{t("edit_profile")}</h3>
            {props.profile.loading ? (
              t("loading")
            ) : (
              <Form className="edit-profile-form" onSubmit={handleSubmit}>
                   <img src={image.picture}  className="image-preview" />
                <Form.Label>{t("profile_image")}</Form.Label>
                <div className="form-content contact-form-action">
                  <Row>
                    <Col lg={12}>
                      <div className="file-upload-wrap">
                        <input
                          type="file"
                          accept="image/*"
                          name="picture"
                          className="multi file-upload-input with-preview"
                          onChange={handleChangeImage}
                        />
                        <span className="file-upload-text">
                          <i className="fa fa-upload mr-2"></i>{t("click_images_here_to_upload")}
                        </span>
                      </div>
                    </Col>
                  </Row>
                </div>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>{t("your_name")}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t("your_name")}
                    name="name"
                    defaultValue={props.profile.data.name}
                    onChange={(event) => {
                      props.dispatch(
                        editUserDetails(
                          event.currentTarget.name,
                          event.currentTarget.value
                        )
                      );
                    }}
                  />
                </Form.Group>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>{t("your_email")}</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder={t("email")}
                    name="email"
                    defaultValue={props.profile.data.email}
                    onChange={(event) => {
                      props.dispatch(
                        editUserDetails(
                          event.currentTarget.name,
                          event.currentTarget.value
                        )
                      );
                    }}
                  />
                </Form.Group>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>{t("your_username")}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t("username")}
                    name="username"
                    defaultValue={props.profile.data.username}
                    onChange={usernameCheck}
                  />
                </Form.Group>

                <Form.Group controlId="formBasicEmail">
                  <Form.Label>{t("video_call_amount")}</Form.Label>
                  <Form.Control
                    type="number"
                    step="any"
                    min='0'
                    placeholder={t("video_call_amount_placeholder")}
                    name="video_call_amount"
                    defaultValue={props.profile.data.video_call_amount}
                    onChange={(event) => {
                      props.dispatch(
                        editUserDetails(
                          event.currentTarget.name,
                          event.currentTarget.value
                        )
                      );
                    }}
                  />
                </Form.Group>

                <Form.Group controlId="formBasicEmail">
                  <Form.Label>{t("your_facebook")}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t("link")}
                    name="facebook_link"
                    defaultValue={props.profile.data.facebook_link}
                    onChange={(event) => {
                      props.dispatch(
                        editUserDetails(
                          event.currentTarget.name,
                          event.currentTarget.value
                        )
                      );
                    }}
                  />
                </Form.Group>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>{t("your_twitter")}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t("link")}
                    name="twitter_link"
                    defaultValue={props.profile.data.twitter_link}
                    onChange={(event) => {
                      props.dispatch(
                        editUserDetails(
                          event.currentTarget.name,
                          event.currentTarget.value
                        )
                      );
                    }}
                  />
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlTextarea1">
                  <Form.Label>{t("your_bio")}</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="about"
                    defaultValue={props.profile.data.about}
                    onChange={(event) => {
                      props.dispatch(
                        editUserDetails(
                          event.currentTarget.name,
                          event.currentTarget.value
                        )
                      );
                    }}
                  />
                </Form.Group>

                <Button
                  type="submit"
                  className="btn-edit-profile"
                  disabled={props.profileInputData.buttonDisable}
                >
                  {props.profileInputData.loadingButtonContent !== null
                    ? props.profileInputData.loadingButtonContent
                    : t("submit")}
                </Button>
              </Form>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  profile: state.users.profile,
  profileInputData: state.users.profileInputData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(withTranslation(EditProfile));
