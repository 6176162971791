import {
  FETCH_WALLET_DETAILS_START,
  FETCH_WALLET_DETAILS_SUCCESS,
  FETCH_WALLET_DETAILS_FAILURE,
  ADD_MONEY_VIA_CARD_START,
  ADD_MONEY_VIA_CARD_SUCCESS,
  ADD_MONEY_VIA_CARD_FAILURE,
  ADD_MONEY_VIA_BANK_START,
  ADD_MONEY_VIA_BANK_SUCCESS,
  ADD_MONEY_VIA_BANK_FAILURE,
  WALLET_HISTORY_START,
  WALLET_HISTORY_SUCCESS,
  WALLET_HISTORY_FAILURE,
  WALLET_MORE_HISTORY_START,
  GENERATE_STRIPE_PAYMENT_START,
  GENERATE_STRIPE_PAYMENT_SUCCESS,
  GENERATE_STRIPE_PAYMENT_FAILURE,
  CHAPA_SUBSCRIPTION_PAYMENT_START,
  CHAPA_SUBSCRIPTION_PAYMENT_SUCCESS,
  CHAPA_SUBSCRIPTION_PAYMENT_FAILURE,
  CHAPA_VIDEO_CALL_PAYMENT_START,
  CHAPA_VIDEO_CALL_PAYMENT_SUCCESS,
  CHAPA_VIDEO_CALL_PAYMENT_FAILURE,
} from "./ActionConstant";

// Get Wallet Details actions.

export function fetchWalletDetailsStart(data) {
  return {
    type: FETCH_WALLET_DETAILS_START,
    data,
  };
}

export function fetchWalletDetailsSuccess(data) {
  return {
    type: FETCH_WALLET_DETAILS_SUCCESS,
    data,
  };
}

export function fetchWalletDetailsFailure(error) {
  return {
    type: FETCH_WALLET_DETAILS_FAILURE,
    error,
  };
}

// add Wallet via card actions.

export function addMoneyViaCardStart(data) {
  return {
    type: ADD_MONEY_VIA_CARD_START,
    data,
  };
}

export function addMoneyViaCardSuccess(data) {
  return {
    type: ADD_MONEY_VIA_CARD_SUCCESS,
    data,
  };
}

export function addMoneyViaCardFailure(error) {
  return {
    type: ADD_MONEY_VIA_CARD_FAILURE,
    error,
  };
}

// add Wallet via bank actions.

export function addMoneyViaBankStart(data) {
  return {
    type: ADD_MONEY_VIA_BANK_START,
    data,
  };
}

export function addMoneyViaBankSuccess(data) {
  return {
    type: ADD_MONEY_VIA_BANK_SUCCESS,
    data,
  };
}

export function addMoneyViaBankFailure(error) {
  return {
    type: ADD_MONEY_VIA_BANK_FAILURE,
    error,
  };
}

// Wallet history

export function walletHistoryStart(data) {
  return {
    type: WALLET_HISTORY_START,
    data,
  };
}

export function walletHistorySuccess(data) {
  return {
    type: WALLET_HISTORY_SUCCESS,
    data,
  };
}

export function walletHistoryFailure(error) {
  return {
    type: WALLET_HISTORY_FAILURE,
    error,
  };
}

export function walletMoreHistoryStart(data) {
  return {
    type: WALLET_MORE_HISTORY_START,
    data,
  };
}

export function generateStripeStart(data) {
  return {
    type: GENERATE_STRIPE_PAYMENT_START,
    data,
  };
}

export function generateStripeSuccess(data) {
  return {
    type: GENERATE_STRIPE_PAYMENT_SUCCESS,
    data,
  };
}

export function generateStripeFailure(error) {
  return {
    type: GENERATE_STRIPE_PAYMENT_FAILURE,
    error,
  };
}

export function chapaSubscriptionPaymentStart(data) {
  return {
    type: CHAPA_SUBSCRIPTION_PAYMENT_START,
    data,
  };
}

export function chapaSubscriptionPaymentSuccess(data) {
  return {
    type: CHAPA_SUBSCRIPTION_PAYMENT_SUCCESS,
    data,
  };
}

export function chapaSubscriptionPaymentFailure(error) {
  return {
    type: CHAPA_SUBSCRIPTION_PAYMENT_FAILURE,
    error,
  };
}

export function chapaVideoCallPaymentStart(data) {
  return {
    type: CHAPA_VIDEO_CALL_PAYMENT_START,
    data,
  };
}

export function chapaVideoCallPaymentSuccess(data) {
  return {
    type: CHAPA_VIDEO_CALL_PAYMENT_SUCCESS,
    data,
  };
}

export function chapaVideoCallPaymentFailure(error) {
  return {
    type: CHAPA_VIDEO_CALL_PAYMENT_FAILURE,
    error,
  };
}