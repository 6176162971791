import React, { useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import configuration from "react-global-configuration";
import { t, withTranslation } from "react-multi-lang";
import {
  Container,
  Row,
  Col,
  Form,
  Image,
  Button,
  Dropdown,
  Media,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import {
  setTranslations,
  setDefaultLanguage,
  setLanguage,
  getLanguage,
} from "react-multi-lang";

const FooterIndex = (props) => {
  const handleChangeLang = ({ currentTarget: input }) => {
    console.log(input.value);
    setLanguage(input.value);
    localStorage.setItem("lang", input.value);
    // window.location.reload();
  };


  return (
    <>
      <div className="footer-sec">
        <Container fluid>
          <Row>
            <Col md={5}>
              <div className="footer-content">
                {/* <h3 className="footer-title">Join our mailing list</h3>
                <p className="footer-desc">
                  Be the first to know about the newest stars and best deals on
                  {configuration.get("configData.site_name")}
                </p> */}
                <h3 className="footer-title">
                  {configuration.get("configData.site_name")}
                </h3>
              </div>
              <div className="footer-subscribe-sec">
                {/* <Form className="subscribe-form">
                  <Form.Group controlId="formBasicEmail">
                    <Form.Control type="email" placeholder="Email address" />
                  </Form.Group>
                  <Button className="btn-subscribe">
                    <FontAwesomeIcon icon={faArrowRight} color="white" />
                  </Button>
                </Form> */}
                {/* <p className="footer-desc">
                  {configuration.get("configData.tag_name")}
                </p> */}
                <Image
                  src={
                    configuration.get("configData.site_logo")
                      ? configuration.get("configData.site_logo")
                      : window.location.origin + "/assets/images/celecloud.svg"
                  }
                  alt={configuration.get("configData.site_name")}
                  className="footer-logo"
                />
              </div>
            </Col>
            <Col md={7}>
              <div className="footer-right-contebt-sec">
                <ul className="list-unstyled social-link">
                  {configuration.get("configData.facebook_link") ? (
                    <Media as="li">
                      <a
                        href={configuration.get("configData.facebook_link")}
                        target="_blank"
                      >
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </Media>
                  ) : null}
                  {configuration.get("configData.instagram_link") ? (
                    <Media as="li">
                      <a
                        href={configuration.get("configData.instagram_link")}
                        target="_blank"
                      >
                        <i className="fab fa-instagram"></i>
                      </a>
                    </Media>
                  ) : null}

                  {configuration.get("configData.twitter_link") ? (
                    <Media as="li">
                      <a
                        href={configuration.get("configData.twitter_link")}
                        target="_blank"
                      >
                        <i className="fab fa-twitter"></i>
                      </a>
                    </Media>
                  ) : null}
                  {configuration.get("configData.youtube_link") ? (
                    <Media as="li">
                      <a
                        href={configuration.get("configData.youtube_link")}
                        target="_blank"
                      >
                        <i className="fab fa-youtube"></i>
                      </a>
                    </Media>
                  ) : null}
                  {configuration.get("configData.linkedin_link") ? (
                    <Media as="li">
                      <a
                        href={configuration.get("configData.linkedin_link")}
                        target="_blank"
                      >
                        <i className="fab fa-linkedin"></i>
                      </a>
                    </Media>
                  ) : null}
                  {configuration.get("configData.pinterest_link") ? (
                    <Media as="li">
                      <a
                        href={configuration.get("configData.pinterest_link")}
                        target="_blank"
                      >
                        <i className="fab fa-pinterest"></i>
                      </a>
                    </Media>
                  ) : null}
                </ul>
                <div className="footer-right-content">
                  {localStorage.getItem("is_model") != "2" &&
                    <p className="footer-desc">
                      {t("ready_talent")}{" "}
                      {configuration.get("configData.site_name")}?
                    </p>
                  }
                  <div className="footer-btn-sec">
                    {localStorage.getItem("is_model") != "2" &&
                      <Link
                        to="/enroll"
                        onClick={() =>
                          window.scrollTo({ top: 0, behavior: "smooth" })
                        }
                      >
                        <Button className="enroll-btn mr-2">
                          {t("enroll_talent")}
                        </Button>
                      </Link>
                    }
                    <Link to="/invite" className="hidden">
                      <Button className="enroll-btn">{t("earn_credit")}</Button>
                    </Link>

                    <div className="select-lang-drop-down">
                      <select
                        className="lang-toggle mw-200"
                        name="lang"
                        onChange={handleChangeLang}
                        defaultValue={localStorage.getItem("lang")}
                      >
                        <option
                          value="en"
                          selected={
                            localStorage.getItem("lang") == "en" ? true : false
                          }
                        >
                          🇬🇧&emsp; {t("english")}
                        </option>
                        <option
                          value="gre"
                          selected={
                            localStorage.getItem("lang") == "gre" ? true : false
                          }
                        >
                          🇬🇷&emsp;{t("greek")}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <ul className="list-unstyled static-link">
                {configuration
                  .get("configData.footer_pages1")
                  .map((static_page, index) => (
                    <Media as="li">
                      <Link
                        to={`/page/${static_page.static_page_unique_id}`}
                        key={static_page.static_page_unique_id}
                        onClick={() =>
                          window.scrollTo({ top: 0, behavior: "smooth" })
                        }
                      >
                        {static_page.title}
                      </Link>
                    </Media>
                  ))}
                {configuration
                  .get("configData.footer_pages2")
                  .map((static_page, index) => (
                    <Media as="li">
                      <Link
                        to={`/page/${static_page.static_page_unique_id}`}
                        key={static_page.static_page_unique_id}
                        onClick={() =>
                          window.scrollTo({ top: 0, behavior: "smooth" })
                        }
                      >
                        {static_page.title}
                      </Link>
                    </Media>
                  ))}
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="copyright-sec text-center">
        <small className="copyright-text">
          {configuration.get("configData.copyright_content")}
        </small>
      </div>
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(null, mapDispatchToProps)(withTranslation(FooterIndex));
