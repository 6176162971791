import React, { useState } from 'react';
import {
    Form,
    Button,
} from "react-bootstrap";

import { withTranslation, t } from 'react-multi-lang';
import { useSelector } from 'react-redux';


const ChapaPayment = (props) => {
    const chapaSubscriptionPayment = useSelector(state => state.wallet.chapaSubscriptionPayment)
    const chapaVideoCallPayment = useSelector(state => state.wallet.chapaVideoCallPayment)
    return (
        <>
            <div className="wallet-modal-details ">
                <h4 className="payment-modal-title">{t("chapa_payment")}</h4>
                <Form onSubmit={props.handleSubmit}>
                    <Form.Group className="mb-3">
                        <Form.Control type="text"
                            value={props.amount}
                            min="50"
                            disabled={true}
                        />
                    </Form.Group>
                    <div className="add-card-btn d-flex align-items-center justify-content-center">
                        <Button type="submit" className='default-btn pay-now'
                            disabled={chapaSubscriptionPayment.buttonDisable || chapaVideoCallPayment.buttonDisable}
                        >
                            {chapaSubscriptionPayment.loadingButtonContent || chapaVideoCallPayment.loadingButtonContent ? chapaSubscriptionPayment.loadingButtonContent || chapaVideoCallPayment.loadingButtonContent : t("pay_now")}</Button>

                    </div>
                </Form>
            </div>
        </>
    )

}

export default withTranslation(ChapaPayment);