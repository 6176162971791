import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { t, withTranslation } from "react-multi-lang";
import "./Home.css";
import api from "../../Environment";
import CategoriesLoader from "../Handlers/Loaders/CategoriesLoader";
import AdVideo from "./AdVideo";
const Ads = () => {

  <style jsx>{`
    .container{
    height:auto;
    border-radius:15px;
    }
    @media screen and (max-width: 600px) {
    .container {
      width:96vw;
    }
    @media screen and (min-width: 600px) {
    .container {
      width:430px;
    }
    `}</style>
  let state = {};

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Make the API request
        const response = await api.getMethod("ads");
        // Update the state with the fetched data
        setData(response.data.data.data);
      } catch (error) {
        // If there is an error, update the error state
        setError(error);
      } finally {
        // Always set loading to false once the fetch is done
        setLoading(false);
      }
    };

    // Call the fetchData function
    fetchData();
  }, []); // Emp

  if (loading || error) {
    return (
      <>
        <CategoriesLoader />
      </>
    );
  }
  return (
    <>
      <div className="home-blog-sec">
        {loading ? (
          <></>
        ) : data.length > 0 ? (
          <>
            <h1 className="text-white">Advertisement</h1>
          </>
        ) : (
          <></>
        )}
        <Container fluid>
          <Row>
            {data.map((item, index) => (
              <Col md={4} className="resp-mrg-btm-xs ">
                <div className="blog-item">
                  <div className="blog-img-sec">
                    {item.media_type == "image" ? (
                      <a
                        href={item.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Image
                          src={item.img_url}
                          alt="user-image"
                          className="blog-img"
                        />
                      </a>
                    ) : (
                      <div className="rounded">
                        <AdVideo url={item.img_url} />
                      </div>
                    )}
                  </div>
                  <div className="blog-details-sec">
                    <a
                      href={item.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <h4 className="title">{item.title}</h4>
                    </a>
                    <p className="desc">{item.description}</p>
                    <a
                      href={item.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.link}
                    </a>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({});
function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(Ads));
