import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import ImageLoader from "../Helper/ImageLoader";
import HomeFeatureLoader from "../Handlers/Loaders/HomeFeatureLoader";
import NoDataFound from "../NoDataFound/NoDataFound";
import Slider from "react-slick";
import { t, withTranslation } from "react-multi-lang";

const ModelSuggestionSec = (props) => {

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    adaptiveHeight: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },

      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  
  return (
    <>
      <div className="home-featured-sec single-filter-people-view-sec">
        <Container fluid>
          <Row>
            <Col md={12}>
              <div className="home-featured-header-sec">
                <Link to="#">
                  <h1 className="slider-header-title">{t("people_also_viewed")}</h1>
                </Link>
                <Link to="/browse/featured">
                    <p className="slider-header-link slider-end">{t("see_all")}</p>
                  </Link>
              </div>
              <Slider {...settings} className="slider-featured-sec">
                {props.models.loading ? (
                  <HomeFeatureLoader />
                ) : props.models.data.models.length > 0 ? (
                  props.models.data.models.map((model) => (
                    <div>
                      <Link to={`/view/${model.username}`}>
                        <div className="feature-item">
                          <div className="featured-card">
                            <div className="overlay-slider"></div>
                            <ImageLoader
                              image={model.picture}
                              alt="user-image"
                              className="featured-img"
                            />
                            {model.is_featured ? (
                              <span className="flag-icon-img-sec">
                                <Image
                                  src={model.picture}
                                  alt="user-image"
                                  className="flag-icon-img"
                                />
                              </span>
                            ) : null}
                            <p className="featured-desc">
                              {model.subscrition_amount}
                            </p>
                          </div>
                          <div className="featured-details-sec">
                            <div className="featured-details">
                              <Link to="#" className="title">
                                {model.name}
                              </Link>
                            </div>
                            <div className="featured-details">
                              <Link to="#" className="desc">
                                {model.category_name}
                              </Link>
                            </div>
                            <div className="featured-details">
                              <Link to="#" className="desc">
                                {model.subscription_amount}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))
                ) : (
                  <NoDataFound />
                )}
              </Slider>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(withTranslation(ModelSuggestionSec));
