import React, { Component } from "react";
import { call, select, put, takeLatest, all } from "redux-saga/effects";
import {
  fetchVideocallModelSuccess,
  fetchVideocallModelFailure,
  getVideocallModelSuccess,
  getVideocallModelFailure,
  statusVideocallModelSuccess,
  statusVideocallModelFailure,
  sendVideocallUserSuccess,
  sendVideocallUserFailure,
  fetchVideocallUserSuccess,
  fetchVideocallUserFailure,
  getVideocallUserSuccess,
  getVideocallUserFailure,
  statusVideocallUserSuccess,
  statusVideocallUserFailure,
  joinVideocallSuccess,
  joinVideocallFailure,
  endVideocallSuccess,
  endVideocallFailure,
  modifyVideocallUserSuccess,
  modifyVideocallModelSuccess,
  videoCallPaymentByStripeSuccess,
  videoCallPaymentByStripeFailure,
  fetchVideoCallViewSuccess,
  fetchVideoCallViewFailure,
  fetchEventModelListSuccess,
  fetchEventModelListFailure,
  startVideocallSuccess,
  startVideocallFailure,
  videoCallsPaymentSuccess,
  videoCallsPaymentFailure,
  videoCallsGenrateStripePaymentSuccess,
  videoCallsGenrateStripePaymentFailure
} from "../actions/VideoCall";
import api from "../../Environment";
import {
  FETCH_VIDEOCALL_MODEL_START,
  FETCH_MORE_VIDEOCALL_MODEL_START,
  GET_VIDEOCALL_MODEL_START,
  STATUS_VIDEOCALL_MODEL_START,
  SEND_VIDEOCALL_USER_START,
  FETCH_VIDEOCALL_USER_START,
  GET_VIDEOCALL_USER_START,
  STATUS_VIDEOCALL_USER_START,
  JOIN_VIDEOCALL_START,
  END_VIDEOCALL_START,
  START_VIDEOCALL_START,
  FETCH_MORE_VIDEOCALL_USER_START,
  VIDEO_CALL_PAYMENT_BY_STRIPE_START,
  FETCH_VIDEO_CALL_VIEW_START,
  FETCH_EVENT_MODEL_LIST_START,
  FETCH_MORE_EVENT_MODEL_LIST_START,
  VIDEO_CALLS_PAYMENT_STATUS_START,
  VIDEO_CALLS_GENERATE_STRIPE_PAYMENT_START,
} from "../actions/ActionConstant";
import { createNotification } from "react-redux-notify";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/Helper/NotificationMessage";
import { checkLogoutStatus } from "../actions/ErrorAction";

function* fetchVideocallModelAPI(action) {
  try {
    const response = yield api.postMethod("model_video_calls", action.data);
    if (response.data.success) {
      yield put(fetchVideocallModelSuccess(response.data.data));
    } else {
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchVideocallModelFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* getVideocallModelAPI() {
  try {
    const response = yield api.postMethod("model_video_call_view");
    if (response.data.success) {
      yield put(getVideocallModelSuccess(response.data.data));
    } else {
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(getVideocallModelFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}



function* statusVideocallModelAPI(action) {
  try {
    const response = yield api.postMethod("model_video_call_status", action.data);
    if (response.data.success) {
      yield put(statusVideocallModelSuccess(response.data.data));
      const modelVideoCall = yield select((state) => state.videocall.modelVideoCall);
      let newUserVideoCall = {
        video_calls: modelVideoCall.data.video_calls.map((data) =>
          data.video_call_unique_id === response.data.data.video_call.video_call_unique_id ?
            response.data.data.video_call
            : data
        ),
        total_video_calls: modelVideoCall.data.total_video_calls,
      }
      yield put(modifyVideocallModelSuccess(newUserVideoCall));
    } else {
      yield put(statusVideocallModelFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(statusVideocallModelFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* sendVideocallUserAPI(action) {
  try {
    const response = yield api.postMethod(
      "video_call_request_send",
      action.data
    );

    if (response.data.success) {
      yield put(sendVideocallUserSuccess(response.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
    } else {
      yield put(sendVideocallUserFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(sendVideocallUserFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchVideocallUserAPI(action) {
  try {
    const response = yield api.postMethod("user_video_calls", action.data);
    if (response.data.success) {
      yield put(fetchVideocallUserSuccess(response.data.data));
    } else {
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchVideocallUserFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* getVideocallUserAPI(action) {
  try {
    const response = yield api.postMethod("user_video_call_view", action.data);
    if (response.data.success) {
      yield put(getVideocallUserSuccess(response.data));
    } else {
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(getVideocallUserFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* statusVideocallUserAPI(action) {
  try {
    const response = yield api.postMethod("user_video_call_status", action.data);
    if (response.data.success) {
      yield put(statusVideocallUserSuccess(response.data.data));
      const userVideoCall = yield select((state) => state.videocall.userVideoCall);
      let newUserVideoCall = {
        video_calls: userVideoCall.data.video_calls.filter((data) => data.video_call_unique_id !== response.data.data.video_call.video_call_unique_id),
        total_video_calls: userVideoCall.data.total_video_calls,
      }
      yield put(modifyVideocallUserSuccess(newUserVideoCall));
    } else {
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(statusVideocallUserFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* joinVideocallAPI(action) {
  try {
    const response = yield api.postMethod("video_call_join", action.data);
    if (response.data.success) {
      yield put(joinVideocallSuccess(response.data.data));
    } else {
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
      if (action.data.isOwner) {
        window.location.assign("/incoming-video-call");
      } else {
        window.location.assign("/my-tickets");
      }
    }
  } catch (error) {
    yield put(joinVideocallFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
    if (action.isOwner) {
      window.location.assign("/incoming-video-call");
    } else {
      window.location.assign("/my-tickets");
    }
  }
}

function* endVideocallAPI(action) {
  try {
    const response = yield api.postMethod("video_call_end", action.data);
    if (response.data.success) {
      yield put(endVideocallSuccess(response.data.data));
      const modelVideoCall = yield select((state) => state.videocall.modelVideoCall);
      let newUserVideoCall = {
        video_calls: modelVideoCall.data.video_calls.map((data) =>
          data.video_call_unique_id === response.data.data.video_call.video_call_unique_id ?
            response.data.data.video_call
            : data
        ),
        total_video_calls: modelVideoCall.data.total_video_calls,
      }
      yield put(modifyVideocallModelSuccess(newUserVideoCall));
    } else {
      yield put(endVideocallFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(endVideocallFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* startVideocallAPI(action) {
  try {
    const response = yield api.postMethod("video_call_start", action.data);
    if (response.data.success) {
      yield put(startVideocallSuccess(response.data.data));
      window.location.assign(`/live-streaming/${response.data.data.video_call_unique_id}`);
    } else {
      yield put(startVideocallFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(startVideocallFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* videoCallpaymentByStripeAPI(action) {
  try {
    const response = yield api.postMethod("video_call_payment_by_stripe", action.data);
    if (response.data.success) {
      yield put(videoCallPaymentByStripeSuccess(response.data.data));
      const userVideoCall = yield select((state) => state.videocall.userVideoCall);
      let newUserVideoCall = {
        video_calls: userVideoCall.data.video_calls.map((data) =>
          data.video_call_id !== response.data.data.video_call_id ? data : response.data.data),
        total_video_calls: userVideoCall.data.total_video_calls,
      }
      yield put(modifyVideocallUserSuccess(newUserVideoCall));
    } else {
      yield put(videoCallPaymentByStripeFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(videoCallPaymentByStripeFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchVideoCallViewAPI(action) {
  try {
    const response = yield api.postMethod("video_call_view", action.data);
    if (response.data.success) {
      yield put(fetchVideoCallViewSuccess(response.data.data));
    } else {
      yield put(fetchVideoCallViewFailure(response.data));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchVideoCallViewFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* modelEventListAPI(action) {
  try {
    const response = yield api.postMethod("events_models_list", action.data);
    if (response.data.success) {
      yield put(fetchEventModelListSuccess(response.data.data));
    } else {
      yield put(fetchEventModelListFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchEventModelListFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* videoCallsPaymentStatusAPI(action) {
  try {
    const response = yield api.postMethod("video_calls_payment_status", action.data);
    if (response.data.success) {
      yield put(videoCallsPaymentSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
    } else {
      yield put(videoCallsPaymentFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(videoCallsPaymentFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* videoCallsGenerateStripeAPI(action) {
  try {
    const response = yield api.postMethod("video_calls_generate_stripe_payment", action.data);
    if (response.data.success) {
      yield put(videoCallsGenrateStripePaymentSuccess(response.data.data));
    } else {
      yield put(videoCallsGenrateStripePaymentFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(videoCallsGenrateStripePaymentFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}


export default function* pageSaga() {
  yield all([
    yield takeLatest(FETCH_VIDEOCALL_MODEL_START, fetchVideocallModelAPI),
    yield takeLatest(FETCH_MORE_VIDEOCALL_MODEL_START, fetchVideocallModelAPI),
    yield takeLatest(GET_VIDEOCALL_MODEL_START, getVideocallModelAPI),
    yield takeLatest(STATUS_VIDEOCALL_MODEL_START, statusVideocallModelAPI),
    yield takeLatest(SEND_VIDEOCALL_USER_START, sendVideocallUserAPI),
    yield takeLatest(FETCH_VIDEOCALL_USER_START, fetchVideocallUserAPI),
    yield takeLatest(FETCH_MORE_VIDEOCALL_USER_START, fetchVideocallUserAPI),
    yield takeLatest(GET_VIDEOCALL_USER_START, getVideocallUserAPI),
    yield takeLatest(STATUS_VIDEOCALL_USER_START, statusVideocallUserAPI),
    yield takeLatest(JOIN_VIDEOCALL_START, joinVideocallAPI),
    yield takeLatest(END_VIDEOCALL_START, endVideocallAPI),
    yield takeLatest(START_VIDEOCALL_START, startVideocallAPI),
    yield takeLatest(VIDEO_CALL_PAYMENT_BY_STRIPE_START, videoCallpaymentByStripeAPI),
    yield takeLatest(FETCH_VIDEO_CALL_VIEW_START, fetchVideoCallViewAPI),
    yield takeLatest(FETCH_EVENT_MODEL_LIST_START, modelEventListAPI),
    yield takeLatest(FETCH_MORE_EVENT_MODEL_LIST_START, modelEventListAPI),
    yield takeLatest(VIDEO_CALLS_PAYMENT_STATUS_START, videoCallsPaymentStatusAPI),
    yield takeLatest(VIDEO_CALLS_GENERATE_STRIPE_PAYMENT_START, videoCallsGenerateStripeAPI),
  ]);
}
