import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import configuration from "react-global-configuration";
import "./Enroll.css";
import { Button, Form, Image } from "react-bootstrap";
import { modelEnrollStart } from "../../store/actions/UserAction";
import { t, withTranslation } from "react-multi-lang";

const EnrollIndex = (props) => {
  const [inputData, setInputData] = useState({});

  const handleSubmit = (event) => {
    event.preventDefault();
    props.dispatch(modelEnrollStart(inputData));
  };

  return (
    <>
      <div className="main-wrapper">
        <div className="enroll-sec">
          <div className="enroll-card">
            <h3 className="title">
              {t("apply_to_join")} {configuration.get("configData.site_name")} {" "} {t("as_talent")}
            </h3>
            <p className="desc">
              {t("if_you_have_fanbase_and_want_to_join")} {" "}
              {configuration.get("configData.site_name")} {t("talent_note")}
            </p>
            <Form className="enroll-form" onSubmit={handleSubmit}>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>{t("name")}*</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t("mathew_perry")}
                  name="name"
                  value={inputData.name}
                  onChange={(event) =>
                    setInputData({
                      ...inputData,
                      name: event.currentTarget.value,
                    })
                  }
                />
                {!inputData.name ? <Form.Text className="short-desc">{t("required")}</Form.Text> : null}
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>{t("email")}*</Form.Label>
                <Form.Control
                  type="email"
                  placeholder={t("you@email.com")}
                  name="email"
                  value={inputData.email}
                  onChange={(event) =>
                    setInputData({
                      ...inputData,
                      email: event.currentTarget.value,
                    })
                  }
                />
                {!inputData.email ? <Form.Text className="short-desc">{t("required")}</Form.Text> : null}
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>{t("phone_number_never_shared")}*</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t("mobile_number")}
                  name="mobile"
                  value={inputData.mobile}
                  onChange={(event) =>
                    setInputData({
                      ...inputData,
                      mobile: event.currentTarget.value,
                    })
                  }
                />
                {!inputData.mobile ? <Form.Text className="short-desc">{t("required")}</Form.Text> : null}
              </Form.Group>
              <Form.Group controlId="pronoun-select">
                <Image
                  src={
                    window.location.origin +
                    "/assets/images/select-arrow-white.svg"
                  }
                  alt="user-image"
                  className="select-arrow"
                />
                <Form.Label>{t("where_can_we_find_you")} *</Form.Label>
                <Form.Control
                  as="select"
                  name="platform"
                  value={inputData.platform}
                  onChange={(event) =>
                    setInputData({
                      ...inputData,
                      platform: event.currentTarget.value,
                    })
                  }
                >
                  <option>{t("select")}</option>
                  <option value="twitter">{t("twitter")}</option>
                  <option value="instagram">{t("instagram")}</option>
                  <option value="youtube">{t("youtube")}</option>
                  <option value="facebook">{t("facebook")}</option>
                  <option value="twitch">{t("twitch")}</option>
                  <option value="tiktok">{t("tikTok")}</option>
                  <option value="others">{t("others")}</option>

                </Form.Control>
               { !inputData.platform ? <Form.Text className="short-desc">{t("required")}</Form.Text> : null}
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>{t("your_handle")} *</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t("jimmybuffet")}
                  name="platform_username"
                  value={inputData.platform_username}
                  onChange={(event) =>
                    setInputData({
                      ...inputData,
                      platform_username: event.currentTarget.value,
                    })
                  }
                />
                {!inputData.platform_username ? <Form.Text className="short-desc">{t("required")}</Form.Text> : null}
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>{t("how_many_followers_do_you_have")}*</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="400k"
                  name="platform_followers"
                  value={inputData.platform_followers}
                  onChange={(event) =>
                    setInputData({
                      ...inputData,
                      platform_followers: event.currentTarget.value,
                    })
                  }
                />
                {!inputData.platform_followers ? <Form.Text className="short-desc">{t("required")}</Form.Text> : null}
              </Form.Group>
              <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Label>{t("anything_else_we_should_know_about_you")}</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  className="height-auto-form"
                  placeholder={t("desc_note")}
                  name="description"
                  value={inputData.description}
                  onChange={(event) =>
                    setInputData({
                      ...inputData,
                      description: event.currentTarget.value,
                    })
                  }
                />
                {!inputData.description ? <Form.Text className="short-desc">{t("required")} <span>250</span></Form.Text> : null}
              </Form.Group>
              <div className="text-center">
                <small className="enroll-text">
                  {t("enroll_note")}{" "}
                  {configuration.get("configData.site_name")}. {t("enroll_sub_note")}
                </small>
              </div>
              <Button
                className="btn-continue btn-block"
                type="submit"
                disabled={
                  !inputData.name ||
                  !inputData.email ||
                  !inputData.mobile ||
                  !inputData.platform_username ||
                  !inputData.platform_followers ||
                  !inputData.description
                }
              >
                {props.modelEnroll.loadingButtonContent != null
                  ? props.modelEnroll.loadingButtonContent
                  : t("submit")}
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  modelEnroll: state.users.modelEnroll,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(withTranslation(EnrollIndex));
