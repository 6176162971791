import React, { Component } from "react";
import { connect } from "react-redux";
import "./StaticPage.css";
import { fetchSinglePageStart } from "../../store/actions/PageAction";
import { Container, Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { t, withTranslation } from "react-multi-lang";

class StaticPage extends Component {
  state = {
    pageData: null,
    loadingPageData: true,
    active: null,
    displayContent: null,
    currentPageTitle: null,
  };
  componentDidMount() {
    this.setState({ currentPageTitle: this.props.match.params.title });
    setTimeout(() => {
      this.props.dispatch(
        fetchSinglePageStart({ unique_id: this.props.match.params.title })
      );
    }, 1000);
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.title !== prevProps.match.params.title) {
      // call the fetch function again
      this.props.dispatch(
        fetchSinglePageStart({ unique_id: this.props.match.params.title })
      );
    }
  }

  changePage = (event, page) => {
    event.preventDefault();
    this.setState({ displayContent: page });
  };

  render() {
    const displayContent = this.props.page;
    return (
      <>
        <div className="main-wrapper static-page-wrapper">
          <div className="static-page-sec">
            <Container>
              <Row>
                <Col md={12}>
                  {displayContent.loading ? (
                    t("loading")
                  ) : (
                    <div>
                      <div className="static-header">
                        <h1>
                          <div>
                            <u>{displayContent.data.title}</u>
                          </div>
                        </h1>
                        <h5 className="text-muted">
                          Updated At:{displayContent.data.updated_at}
                        </h5>
                      </div>

                      <div className="static-body">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: displayContent.data.description,
                          }}
                        ></p>
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToPros = (state) => ({
  page: state.page.pageData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(withTranslation(StaticPage));
