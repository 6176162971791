import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Image,
  Media,
  Button,
  DropdownButton,
  Dropdown,
  Form,
} from "react-bootstrap";
import { connect } from "react-redux";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { Calendar, utils } from "react-modern-calendar-datepicker";
import configuration from "react-global-configuration";
import { useParams } from "react-router";
import { fetchSingleModelStart } from "../../store/actions/ModelAction";
import { sendVideocallUserStart } from '../../store/actions/VideoCall';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useHistory } from 'react-router-dom';
import { t, withTranslation } from "react-multi-lang";

const EventsRequest = (props) => {

  const times = [
    { label: "12:00 am", value: "00:00:00" }, { label: "12:15 am", value: "00:15:00" }, { label: "12:30 am", value: "00:30:00" }, { label: "12:45 am", value: "00:45:00" },
    { label: "1:00 am", value: "01:00:00" }, { label: "1:15 am", value: "01:15:00" }, { label: "1:30 am", value: "01:30:00" }, { label: "1:45 am", value: "01:45:00" },
    { label: "2:00 am", value: "02:00:00" },  { label: "2:15 am", value: "02:15:00" }, { label: "2:30 am", value: "02:30:00" },  { label: "2:45 am", value: "02:45:00" },
    { label: "3:00 am", value: "03:00:00" },  { label: "3:15 am", value: "03:15:00" }, { label: "3:30 am", value: "03:30:00" },  { label: "3:45 am", value: "03:45:00" },
    { label: "4:00 am", value: "04:00:00" },  { label: "4:15 am", value: "04:15:00" },  { label: "4:30 am", value: "04:30:00" }, { label: "4:45 am", value: "04:45:00" }, 
    { label: "5:00 am", value: "05:00:00" }, { label: "5:15 am", value: "05:15:00" }, { label: "5:30 am", value: "05:30:00" }, { label: "5:45 am", value: "05:45:00" },
    { label: "6:00 am", value: "06:00:00" },  { label: "6:15 am", value: "06:15:00" }, { label: "6:30 am", value: "06:30:00" },  { label: "6:45 am", value: "06:45:00" },
    { label: "7:00 am", value: "07:00:00" }, { label: "7:15 am", value: "07:15:00" },  { label: "7:30 am", value: "07:30:00" },  { label: "7:45 am", value: "07:45:00" }, 
    { label: "8:00 am", value: "08:00:00" },  { label: "8:15 am", value: "08:15:00" }, { label: "8:30 am", value: "08:30:00" },  { label: "8:45 am", value: "08:45:00" },
    { label: "9:00 am", value: "09:00:00" }, { label: "9:15 am", value: "09:15:00" }, { label: "9:30 am", value: "09:30:00" }, { label: "9:45 am", value: "09:45:00" },
    { label: "10:00 am", value: "10:00:00" }, { label: "10:15 am", value: "10:15:00" },  { label: "10:30 am", value: "10:30:00" }, { label: "10:45 am", value: "10:45:00" }, 
    { label: "11:00 am", value: "11:00:00" },  { label: "11:15 am", value: "11:15:00" }, { label: "11:30 am", value: "11:30:00" },  { label: "11:45 am", value: "11:45:00" }, 
    { label: "12:00 pm", value: "12:00:00" },  { label: "12:15 pm", value: "12:15:00" }, { label: "12:30 pm", value: "12:30:00" },  { label: "12:45 pm", value: "12:45:00" },
    { label: "1:00 pm", value: "13:00:00" },  { label: "1:15 pm", value: "13:15:00" },{ label: "1:30 pm", value: "13:30:00" },  { label: "1:45 pm", value: "13:45:00" },
    { label: "2:00 pm", value: "14:00:00" },  { label: "2:15 pm", value: "14:15:00" },{ label: "2:30 pm", value: "14:30:00" },  { label: "2:45 pm", value: "14:45:00" },
    { label: "3:00 pm", value: "15:00:00" }, { label: "3:15 pm", value: "15:15:00" }, { label: "3:30 pm", value: "15:30:00" }, { label: "3:45 pm", value: "15:45:00" },
    { label: "4:00 pm", value: "16:00:00" },  { label: "4:15 pm", value: "16:15:00" }, { label: "4:30 pm", value: "16:30:00" },  { label: "4:45 pm", value: "16:45:00" },
    { label: "5:00 pm", value: "17:00:00" },  { label: "5:15 pm", value: "17:15:00" }, { label: "5:30 pm", value: "17:30:00" },  { label: "5:45 pm", value: "17:45:00" },
    { label: "6:00 pm", value: "18:00:00" },  { label: "6:15 pm", value: "18:15:00" }, { label: "6:30 pm", value: "18:30:00" },  { label: "6:45 pm", value: "18:45:00" },
    { label: "7:00 pm", value: "19:00:00" }, { label: "7:15 pm", value: "19:15:00" },{ label: "7:30 pm", value: "19:30:00" },   { label: "7:45 pm", value: "19:45:00" },
    { label: "8:00 pm", value: "20:00:00" }, { label: "8:15 pm", value: "20:15:00" }, { label: "8:30 pm", value: "20:30:00" }, { label: "8:45 pm", value: "20:45:00" },
    { label: "9:00 pm", value: "21:00:00" },  { label: "9:15 pm", value: "21:15:00" }, { label: "9:30 pm", value: "21:30:00" },  { label: "9:45 pm", value: "21:45:00" }, 
    { label: "10:00 pm", value: "22:00:00" }, { label: "10:15 pm", value: "22:15:00" }, { label: "10:30 pm", value: "22:30:00" }, { label: "10:45 pm", value: "22:45:00" },
    { label: "11:00 pm", value: "23:00:00" },  { label: "11:15 pm", value: "23:15:00" }, { label: "11:30 pm", value: "23:30:00" },  { label: "11:45 pm", value: "23:45:00" },
  ];

  const history = useHistory();

  const [erroMsg, setErrorMsg] = useState("");

  const [selectedTime, setSelectedTime] = useState();

  const params = useParams();

  useEffect(() => {
    props.dispatch(fetchSingleModelStart({
      username: params.username,
    }));
  }, [params.username]);

  const [skipRender, setSkipRender] = useState(true);

  useEffect(() => {
    if (!skipRender)
      if (!props.sendUserVideoCall.loading && Object.keys(props.sendUserVideoCall.data).length > 0)
        history.push("/my-tickets");
    setSkipRender(false);
  }, [props.sendUserVideoCall]);

  const formatNumber = num => {
    if (num < 10)
      return "0" + num;
    else
      return num;
  }

  const submitForm = (e) => {
    e.preventDefault();
    if (selectedDay && selectedTime) {
      let scheduleTime = selectedDay.year + "-" + formatNumber(selectedDay.month) +
        "-" + formatNumber(selectedDay.day) + " " + selectedTime;
      props.dispatch(sendVideocallUserStart({
        model_id: props.singleModel.data.user.user_id,
        schedule_time: scheduleTime,
        message: message,
      }));
    } else {
      setErrorMsg(t("select_a_time_slot"));
    }
  }


  const today = utils().getToday();

  const [message, setMessage] = useState("");
  const [selectedDay, setSelectedDay] = useState(today);

  useEffect(() => {
    setSelectedTime();
  }, [selectedDay]);

  const TimeButton = ({ time }) => {
    if (selectedDay.day === today.day &&
      selectedDay.month === today.month &&
      selectedDay.year === today.year) {
      let buttonDate = new Date(`${selectedDay.year}-${formatNumber(selectedDay.month)}-${formatNumber(selectedDay.day)} ${time.value}`);
      let currentDate = new Date();
      if (buttonDate.getTime() > currentDate.getTime()) {
        return <Button
          className={`time-btn ${selectedTime === time.value ? "active" : ""}`}
          onClick={() => {
            setSelectedTime(time.value);
            setErrorMsg("");
          }}
        >{time.label}</Button>
      } else {
        return <></>;
      }
    } else {
      return (
        <Button
          className={`time-btn ${selectedTime === time.value ? "active" : ""}`}
          onClick={() => {
            setSelectedTime(time.value);
            setErrorMsg("");
          }}
        >{time.label}</Button>
      )
    }
  }

  let selectedDate = new Date(`${selectedDay.year}-${formatNumber(selectedDay.month)}-${formatNumber(selectedDay.day)}`);
  return (
    <>
      <div className="events-request-sec">
        <Container>
          {props.singleModel.loading ?
            <Row>
              <Col md={5}>
                <Skeleton height={870} />
              </Col>
              <Col md={7}>
                <Skeleton height={870} />
              </Col>
            </Row>
            : <Row>
              <Col md={5}>
                <div className="events-request-box">
                  <div className="events-details-card">
                    <div className="events-request-user-img-sec">
                      <Image
                        src={props.singleModel.data.user.picture}
                        alt="user-image"
                        className="event-request-user-img"
                      />
                    </div>
                    <div className="events-request-user-info">
                      <h6>{configuration.get("configData.site_name")} {t("live_video_call_with")}</h6>
                      <h4>{props.singleModel.data.user.name}</h4>
                    </div>
                    <div className="events-request-user-list-sec">
                      <ul className="list-unstyled">
                        <Media as="li">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="#FFFFFF"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <circle cx="12" cy="12" r="10"></circle>
                            <polyline points="12 6 12 12 16 14"></polyline>
                          </svg>
                          <span>{configuration.get("configData.video_call_duration")} {t("minutes")}</span>
                        </Media>
                        <Media as="li">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="#FFFFFF"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <polygon points="23 7 16 12 23 17 23 7"></polygon>
                            <rect
                              x="1"
                              y="5"
                              width="15"
                              height="14"
                              rx="2"
                              ry="2"
                            ></rect>
                          </svg>
                          <span>
                          {configuration.get("configData.site_name")} {t("live_details_provided_upon_confirmation")}
                          </span>
                        </Media>
                        {/* <Media as="li">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="#FFFFFF"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                          <circle cx="9" cy="7" r="4"></circle>
                          <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                          <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                        </svg>
                        <span>Up to 10 people</span>
                      </Media> */}
                        <Media as="li">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="#FFFFFF"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <rect
                              x="1"
                              y="4"
                              width="22"
                              height="16"
                              rx="2"
                              ry="2"
                            ></rect>
                            <line x1="1" y1="10" x2="23" y2="10"></line>
                          </svg>
                          <span>{props.singleModel.data.user.video_call_amount_formatted}</span>
                        </Media>
                      </ul>
                    </div>
                    <div className="after-requesting-notes">
                      <h5>
                        {t("after_your_request_is_accepted_you’ll_receive_an_email_with_your")}:
                      </h5>
                      <ul className="list-unstyled">
                        <Media as="li">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="#00ab55"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <polyline points="20 6 9 17 4 12"></polyline>
                          </svg>
                          <span>{t("selected_date_and_time")}</span>
                        </Media>
                        <Media as="li">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="#00ab55"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <polyline points="20 6 9 17 4 12"></polyline>
                          </svg>
                          <span>{t("link_to_the")} {("")} {configuration.get(" configData.site_name")} {t("live_call")}
                          </span>
                        </Media>
                      </ul>
                      <p>
                       {t("want_more_info_check_out_our")}{" "}
                        <Link to="/page/Faq">{t("faq_page")}</Link>
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={7}>
                <div className="events-request-box">
                  <div className="event-request-select-date-time">
                    <h4>{t("select_a_date_and_time")}</h4>
                    <p>
                     {t("schedule_a_call_based_on_the_celebrity_availability")}
                    </p>
                  </div>
                  <div className="date-time-picker-card">
                    <div className="date-picker">
                      <Calendar
                        value={selectedDay}
                        onChange={setSelectedDay}
                        shouldHighlightWeekends
                        minimumDate={utils().getToday()}
                      />
                    </div>
                    <div className="time-picker">
                      <div className="date-picker-selected-card">
                        <h6>{selectedDate.toLocaleString('en-us', { weekday: 'long' })}, {selectedDate.toLocaleString('default', { month: 'long' })} {selectedDay.day}</h6>
                        <p>{t("all_times_in")} </p>
                      </div>
                      <div className="time-picker-box">
                        <div className="time-picker-card">
                          {times.map((time, i) =>
                            <TimeButton
                              time={time}
                            />
                          )}
                        </div>
                        <div className="text-danger small">{erroMsg}</div>
                      </div>
                    </div>
                  </div>
                  <div className="event-selection-sec mt-5">
                    {/* <div class="event-request-select-date-time">
                    <h4>How many participants?</h4>
                    <p>You can have a max of 10 participants</p>
                  </div>
                  <div className="event-selection-dropdown">
                    <DropdownButton
                      id="dropdown-basic-button"
                      title="Select an option"
                    >
                      <Dropdown.Item href="#/action-1">
                        Small group (1-5)
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-2">
                        Large group (5-10)
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-3">Unsure</Dropdown.Item>
                    </DropdownButton>
                  </div> */}
                    <div className="selection-text-area">
                      <div className="event-request-select-date-time mt-4">
                        <h4 className="m-0">{t("what_do_you_want_to_talk_about")}</h4>
                        <p>{t("share_the_thoughts_that_you_have_behind_the_call")}</p>
                      </div>
                      <Form onSubmit={submitForm}>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlTextarea1"
                        >
                          <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="eg:- My friends and I have been fans of you for years! We just want to see what you're working on and share some of the impacts you've had on our lives"
                            value={message}
                            onChange={e => setMessage(e.target.value)}
                          />
                        </Form.Group>
                        <div className="selection-payment-btn">
                          <Button
                            type="submit"
                            disabled={props.sendUserVideoCall.buttonDisable ||
                              selectedTime == undefined ||
                              message.length < 5
                            }
                          >
                            {props.sendUserVideoCall.loadingButtonContent ?
                              props.sendUserVideoCall.loadingButtonContent
                              : t("send_request")}</Button>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          }
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  singleModel: state.model.singleModel,
  sendUserVideoCall: state.videocall.sendUserVideoCall,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(withTranslation(EventsRequest));
