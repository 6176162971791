import React, { useState, useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { connect } from "react-redux";
import configuration from "react-global-configuration";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t, withTranslation } from "react-multi-lang";
import {
  faHighlighter,
  faInbox,
  faSearch,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import {
  Navbar,
  Nav,
  InputGroup,
  FormControl,
  Image,
  Media,
  Dropdown,
  NavDropdown,
  Badge,
  Col,
  Tab,
  Row,
} from "react-bootstrap";
import { useHistory } from "react-router";
import { modelSearchStart } from "../../../store/actions/UserAction";
import { fetchCategoryStart } from "../../../store/actions/LookUpAction";


const AuthHeaderIndex = (props) => {
  const params = useParams();
  const location = useLocation();
  const history = useHistory();

  const [show, toggleShow] = useState(false);
  const [tabActiveKey, setTabActiveKey] = useState("");
  const [selectedOptions, setSelectedOptions] = useState({});
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    setSelectedOptions(params);
  }, [params]);

  const resetInputField = () => {
    setInputValue("");
    toggleShow("");
  };

  const handleSearch = (event) => {
    setInputValue(event.target.value);
    if (event.currentTarget.value === "") {
      toggleShow(false);
    } else {
      toggleShow(true);
      props.dispatch(modelSearchStart({ key: event.currentTarget.value }));
    }
  };
  return (
    <>
      <Navbar className="header-nav" expand="lg">
        <Navbar.Brand>
          <Link to="/">
            <Image
              src={configuration.get("configData.site_logo")}
              alt={configuration.get("configData.site_name")}
              className="logo header-logo"
            />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="header-list">
            <div className="nav-list">
              {!props.category.loading &&
                props.category.data.categories &&
                props.category.data.categories.length > 0 ? (
                <NavDropdown
                  title="Categories"
                  id="basic-nav-dropdown"
                  className="categories-dropdown"
                  onClick={() => toggleShow(false)}
                >
                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey="actors"
                    activeKey={tabActiveKey}
                  >
                    <div className="categories-left-side">
                      <Nav variant="pills" className="flex-column">
                        {props.category.data.categories.map((category, i) => (
                          <Nav.Item
                            onMouseEnter={() =>
                              setTabActiveKey(category.category_unique_id)
                            }
                            key={i}
                          >
                            <Nav.Link
                              eventKey={category.name}
                              className={
                                selectedOptions.category_unique_id === category.category_unique_id
                                  ? "active" : ""
                              }
                            >
                              {category.name}
                            </Nav.Link>
                          </Nav.Item>
                        ))}
                      </Nav>
                    </div>
                    <div className="categories-right-side">
                      <Tab.Content>
                        {props.category.data.categories &&
                          props.category.data.categories.map((category, i) => (
                            <Tab.Pane eventKey={category.category_unique_id}>
                              <ul className="categories-right-list-sec">
                                {category.sub_categories && (
                                  <>
                                    {category.sub_categories
                                      .slice(0, 11)
                                      .map((subCategory, i) => (
                                        <Media as="li" key={i}>
                                          <Link
                                            className={`
                                              ${selectedOptions.sub_category_unique_id === subCategory.sub_category_unique_id
                                                ? "active" : ""}
                                            `}
                                            // to={`/browse/${subCategory.sub_category_unique_id}`}
                                            to={{
                                              pathname: `/browse/${category.category_unique_id}/${subCategory.sub_category_unique_id}`,
                                              type: "subcategory",
                                            }}
                                          >
                                            {subCategory.name}
                                          </Link>
                                        </Media>
                                      ))}
                                    {category.sub_categories.length > 11 && (
                                      <Media as="li">
                                        <Link to={`/browse/${category.category_unique_id}`}>
                                          View All{" "}
                                          <i className="fas fa-arrow-right"></i>
                                        </Link>
                                      </Media>
                                    )}
                                  </>
                                )}
                              </ul>
                            </Tab.Pane>
                          ))}
                      </Tab.Content>
                    </div>
                  </Tab.Container>
                </NavDropdown>
              ) : null}
              <NavDropdown
                title="Events"
                id="basic-nav-dropdown"
                className="hidden"
              >
                <NavDropdown.Item href="#action/3.1">
                  Sitename Calls
                </NavDropdown.Item>
                <NavDropdown.Item href="#action/3.2">
                  Sitename Live
                </NavDropdown.Item>
              </NavDropdown>
              {localStorage.getItem("user_unique_id") ?
                <Nav.Item className={location.pathname === "/events" ? "link-active" : ""}>

                  <Nav.Link>

                    <Link
                      to="/events"
                    >
                      {t("Events")}
                    </Link>

                  </Nav.Link>

                </Nav.Item>
                : null
              }
              <Nav.Item className={location.pathname === "/browse/featured" ? "link-active" : ""}>

                <Nav.Link>
                  <Link
                    to={{
                      pathname: `/browse/featured`,
                      type: "category",
                    }}
                    onClick={() => toggleShow(false)}
                  >
                    {t("featured")}
                  </Link>
                </Nav.Link>

              </Nav.Item>
              <Nav.Item className={location.pathname === "/page/About" ? "link-active" : ""}>
                <Nav.Link>
                  <Link
                    to={{
                      pathname: `/page/About`,
                    }}
                    onClick={() => toggleShow(false)}
                  >
                    {t("about")}
                  </Link>
                </Nav.Link>
              </Nav.Item>
            </div>

            <div className="header-search">
              <InputGroup className="search-header-bar">
                <InputGroup.Prepend>
                  <InputGroup.Text id="basic-addon1">
                    {" "}
                    <FontAwesomeIcon icon={faSearch} color="gray" />
                  </InputGroup.Text>
                </InputGroup.Prepend>

                <FormControl
                  placeholder="Search..."
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  name="key"
                  onChange={handleSearch}
                  autoComplete="off"
                  type="text"
                  value={inputValue}
                />

                <InputGroup.Prepend>
                  <InputGroup.Text
                    id="basic-addon1"
                    style={{
                      border: "0",
                      borderTopRightRadius: "20px",
                      borderBottomRightRadius: "20px",
                      cursor: "pointer",
                    }}
                  >
                    {show != "" && (
                      <FontAwesomeIcon
                        icon={faTimes}
                        color="green"
                        onClick={resetInputField}
                      />
                    )}
                  </InputGroup.Text>
                </InputGroup.Prepend>
              </InputGroup>

              {show && (
                <div className="search-suggestion-sec">
                  <ul className="list-unstyled search-dropdown-list-sec">
                    {props.modelSearch.loading ? (
                      "Loading..."
                    ) : props.modelSearch.data.users.length > 0 ? (
                      props.modelSearch.data.users.map((user) => (
                        <Media as="li">
                          <Link
                            to={`/${user.username}`}
                            onClick={() => toggleShow(false)}
                          >
                            <div className="search-body">
                              <div className="user-img-sec">
                                <Image
                                  alt="#"
                                  src={user.picture}
                                  className="user-img"
                                />
                              </div>
                              <div className="search-content">
                                <h5 className="title">{user.name}</h5>
                                <p className="text-muted">
                                  {user.category_name} - {user.subcategory_name}
                                </p>
                              </div>
                            </div>
                          </Link>
                        </Media>
                      ))
                    ) : (
                      <div className="search-no-result-sec">
                        <p>{t("no_search_result_found")}</p>
                        <div className="no-result-search-header">
                          <div className="no-result-search-icon">
                            <i className="fas fa-search"></i>
                          </div>
                          <div className="no-result-search-info">
                            <p>"{inputValue}"</p>
                          </div>
                        </div>
                      </div>
                    )}
                  </ul>
                </div>
              )}

              {localStorage.getItem("user_unique_id") ? (
                <Dropdown>
                  <Dropdown.Toggle
                    className="header-dropdown"
                    id="dropdown-basic"
                  >
                    <div className="user-dropdown-img-sec">
                      <Image
                        src={localStorage.getItem("user_picture")}
                        alt={localStorage.getItem("username")}
                        className="user-img"
                      />
                    </div>
                    <i className="fas fa-chevron-down header-drop-icon"></i>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="header-dropdown-menu">
                    <ul className="list-unstyled header-drop-down-item">
                      <Media as="li">
                        <Link to={`/profile`}>{t("account")}</Link>
                      </Media>
                      <Media as="li">
                        <Link to={`/followings`}>{t("following")}</Link>
                      </Media>
                      <Media as="li">
                        <Link to={`/settings`}>{t("settings")}</Link>
                      </Media>
                      <Media as="li">
                        <Link to={`/requests`}>{t("my_requests")}</Link>
                      </Media>
                      <Media as="li" className="hidden">
                        <Link to="/invite">Invite friends</Link>
                        <Badge className="amount-badge-1">Get $5</Badge>
                      </Media>
                      <Media as="li">
                        <Link to={`/logout`}>{t("logout")}</Link>
                      </Media>
                    </ul>
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                <Link to="/login" className="nav-link">
                  {t("login")}
                </Link>
              )}
            </div>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

const mapStateToPros = (state) => ({
  modelSearch: state.users.modelSearch,
  category: state.lookup.category,

});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(AuthHeaderIndex));
