import React, { Component } from "react";
import { Notify } from "react-redux-notify";

class EmptyLayout extends Component {
  state = {};
  render() {
    return (
      <div className="">
        <Notify position="TopRight" />
        <div className="empty-layout">
          {React.cloneElement(this.props.children)}
        </div>
      </div>
    );
  }
}

export default EmptyLayout;
