import { call, select, put, takeLatest, all } from "redux-saga/effects";

import api from "../../Environment";
import {
  FETCH_WALLET_DETAILS_START,
  ADD_MONEY_VIA_BANK_START,
  ADD_MONEY_VIA_CARD_START,
  WALLET_HISTORY_START,
  WALLET_MORE_HISTORY_START,
  GENERATE_STRIPE_PAYMENT_START,
  CHAPA_SUBSCRIPTION_PAYMENT_START,
  CHAPA_VIDEO_CALL_PAYMENT_START
} from "../actions/ActionConstant";
import { createNotification } from "react-redux-notify";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/Helper/NotificationMessage";
import {
  fetchWalletDetailsSuccess,
  fetchWalletDetailsFailure,
  addMoneyViaCardSuccess,
  addMoneyViaCardFailure,
  addMoneyViaBankSuccess,
  addMoneyViaBankFailure,
  walletHistorySuccess,
  walletHistoryFailure,
  generateStripeSuccess,
  generateStripeFailure,
  chapaVideoCallPaymentSuccess,
  chapaVideoCallPaymentFailure,
  chapaSubscriptionPaymentSuccess,
  chapaSubscriptionPaymentFailure
} from "../actions/WalletAction";
import { checkLogoutStatus } from "../actions/ErrorAction";

function* fetchWalletDetailsAPI() {
  try {
    const response = yield api.postMethod("wallets_index");
    if (response.data.success) {
      yield put(fetchWalletDetailsSuccess(response.data.data));
      // Do nothing
    } else {
      yield put(checkLogoutStatus(response.data));
      yield put(fetchWalletDetailsFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchWalletDetailsFailure(error));
    const notificationMessage = getErrorNotificationMessage(
      error.response.data.error
    );
    yield put(createNotification(notificationMessage));
  }
}

function* addMoneyViaCardAPI() {
  try {
    const inputData = yield select((state) => state.wallet.addMoneyInput.data);
    const response = yield api.postMethod(
      "wallets_add_money_by_stripe",
      inputData
    );
    if (response.data.success) {
      yield put(addMoneyViaCardSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      window.location.assign("/wallet");
    } else {
      yield put(addMoneyViaCardFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(addMoneyViaCardFailure(error));
    const notificationMessage = getErrorNotificationMessage(
      error.response.data.error
    );
    yield put(createNotification(notificationMessage));
  }
}

function* addMoneyViaBankAPI() {
  try {
    const inputData = yield select((state) => state.wallet.addMoneyInput.data);
    const response = yield api.postMethod(
      "wallets_add_money_by_bank_account",
      inputData
    );
    if (response.data.success) {
      yield put(addMoneyViaBankSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      window.location.assign("/wallet");
    } else {
      yield put(addMoneyViaBankFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(addMoneyViaBankFailure(error));
    const notificationMessage = getErrorNotificationMessage(
      error.response.data.error
    );
    yield put(createNotification(notificationMessage));
  }
}

function* walletHistoryAPI(action) {
  try {
    const response = yield api.postMethod("wallets_history", action.data);
    if (response.data.success) {
      yield put(walletHistorySuccess(response.data.data));
      // Do nothing
    } else {
      yield put(checkLogoutStatus(response.data));
      yield put(walletHistoryFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(walletHistoryFailure(error));
    const notificationMessage = getErrorNotificationMessage(
      error.response.data.error
    );
    yield put(createNotification(notificationMessage));
  }
}

function* generateStripeAPI(action) {
  try {
    const response = yield api.postMethod("wallets_generate_stripe_payment", action.data);
    if (response.data.success) {
      yield put(generateStripeSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(generateStripeFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(generateStripeFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* chapaSubscriptionPaymentAPI(action) {
  try {
    const response = yield api.postMethod("wallets_add_money_by_chapa", action.data);
    if (response.data.success) {
      yield put(chapaSubscriptionPaymentSuccess(response.data.data));
    } else {
      yield call(getErrorNotificationMessage, response.data.error);
      yield put(chapaSubscriptionPaymentFailure(response.data.error));

    }
  } catch (error) {
    yield put(chapaSubscriptionPaymentFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* chapaVideoCallPaymentAPI(action) {
  try {
    const response = yield api.postMethod("video_call_payment_by_chapa", action.data);
    if (response.data.success) {
      yield put(chapaVideoCallPaymentSuccess(response.data.data));
    } else {
      // yield call(getErrorNotificationMessage, response.data.error);
      yield put(chapaVideoCallPaymentFailure(response.data.error));

    }
  } catch (error) {
    yield put(chapaVideoCallPaymentFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}


export default function* pageSaga() {
  yield all([
    yield takeLatest(FETCH_WALLET_DETAILS_START, fetchWalletDetailsAPI),
    yield takeLatest(ADD_MONEY_VIA_BANK_START, addMoneyViaBankAPI),
    yield takeLatest(ADD_MONEY_VIA_CARD_START, addMoneyViaCardAPI),
    yield takeLatest(WALLET_HISTORY_START, walletHistoryAPI),
    yield takeLatest(WALLET_MORE_HISTORY_START, walletHistoryAPI),
    yield takeLatest(GENERATE_STRIPE_PAYMENT_START, generateStripeAPI),
    yield takeLatest(CHAPA_SUBSCRIPTION_PAYMENT_START, chapaSubscriptionPaymentAPI),
    yield takeLatest(CHAPA_VIDEO_CALL_PAYMENT_START, chapaVideoCallPaymentAPI)
  ]);
}
