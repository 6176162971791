import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { t, withTranslation } from "react-multi-lang";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import configuration from "react-global-configuration";

class HomeBlog extends Component {
  state = {};
  render() {
    return (
      <>
        <div className="home-blog-sec">
          <Container fluid>
            <Row>
              <Col md={4} className="resp-mrg-btm-xs">
                <div className="blog-item">
                  <div className="blog-img-sec">
                    <Link to="/browse/featured">
                      <Image
                        src={
                          window.location.origin + "/assets/images/blog-1.jpg"
                        }
                        alt="user-image"
                        className="blog-img"
                      />
                    </Link>
                  </div>
                  <div className="blog-details-sec">
                    <Link to="/browse/featured">
                      <h4 className="title">{t("first_blog_title")}</h4>
                    </Link>
                    <p className="desc">{t("first_blog_sub_title")}</p>
                  </div>
                  <div className="blog-view-all-sec">
                    <Link to="/browse/featured" className="see-more-link">
                      {t("see_all")}{" "}
                      <FontAwesomeIcon
                        icon={faArrowRight}
                        color="white"
                        className="ml-1"
                      />
                    </Link>
                  </div>
                </div>
              </Col>
              <Col md={4} className="resp-mrg-btm-xs">
                <div className="blog-item">
                  <div className="blog-img-sec">
                    <Link to="/browse/trending">
                      <Image
                        src={
                          window.location.origin + "/assets/images/blog-2.jpg"
                        }
                        alt="user-image"
                        className="blog-img"
                      />
                    </Link>
                  </div>
                  <div className="blog-details-sec">
                    <Link to="/browse/trending">
                      <h4 className="title">{t("second_blog_title")}</h4>
                    </Link>
                    <p className="desc">{t("second_blog_sub_title")} {(" ")}  {configuration.get("configData.site_name")} {t("legends_para")}</p>
                  </div>
                  <div className="blog-view-all-sec">
                    <Link to="/browse/trending" className="see-more-link">
                      {t("see_all")}{" "}
                      <FontAwesomeIcon
                        icon={faArrowRight}
                        color="white"
                        className="ml-1"
                      />
                    </Link>
                  </div>
                </div>
              </Col>
              <Col md={4}>
                <div className="blog-item">
                  <div className="blog-img-sec">
                    <Link to="/browse/featured">
                      <Image
                        src={
                          window.location.origin + "/assets/images/blog-3.jpg"
                        }
                        alt="user-image"
                        className="blog-img"
                      />
                    </Link>
                  </div>
                  <div className="blog-details-sec">
                    <Link to="/browse/featured">
                      <h4 className="title">{t("third_blog_title")}</h4>
                    </Link>
                    <p className="desc">{t("third_blog_sub_title")}</p>
                  </div>
                  <div className="blog-view-all-sec">
                    <Link to="/browse/featured" className="see-more-link">
                      {t("see_all")}{" "}
                      <FontAwesomeIcon
                        icon={faArrowRight}
                        color="white"
                        className="ml-1"
                      />
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

const mapStateToPros = (state) => ({});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(HomeBlog));
