import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import {
  Image,
  Button,
} from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { getSuccessNotificationMessage } from "../Helper/NotificationMessage";
import { createNotification } from "react-redux-notify";
import {
  endVideocallStart,
  statusVideocallModelStart,
  statusVideocallUserStart,
  videoCallPaymentByStripeStart,
  startVideocallStart,
} from '../../store/actions/VideoCall';
import JoinVideoCallModal from "../Helper/JoinVideoCallModal";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { t, withTranslation } from "react-multi-lang";
import { confirmAlert } from 'react-confirm-alert';
import { videoCallsGenrateStripePaymentStart } from '../../store/actions/VideoCall';
import { videoCallsPaymentStart } from '../../store/actions/VideoCall';
import { fetchVideocallUserStart } from "../../store/actions/VideoCall";
import { useSelector, useDispatch } from 'react-redux';
import { chapaVideoCallPaymentStart } from '../../store/actions/WalletAction';
import PaymentModal from './PaymentModal';

const MyTicketsTableRow = (props) => {
  const dispatch = useDispatch()
  const hisotry = useHistory();
  const { videoCall, index } = props;
  const chapaVideoCallPayment = useSelector(state => state.wallet.chapaVideoCallPayment)
  const [clientSecret, setClientSecret] = useState("");
  const [skipRender, setSkipRender] = useState(true);
  const [videoCallId, setVideoCallId] = useState(null);
  const [paymentModal, setPaymentModal] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const [amount, setAmount] = useState(0);
  const [joinVideoCall, setJoinVideoCall] = useState(false);

  const handleChapaVideoCallPayment = (event) => {
    event.preventDefault();
    dispatch(chapaVideoCallPaymentStart({ amount: amount, video_call_id: videoCallId }))
  }

  useEffect(() => {
    if (!skipRender && !props.videoCallsGenerateStripe.loading &&
      Object.keys(props.videoCallsGenerateStripe.data).length > 0) {
      setClientSecret(props.videoCallsGenerateStripe.data.clientSecret);
    }
    setSkipRender(false);
  }, [props.videoCallsGenerateStripe]);

  useEffect(() => {
    if (!skipRender && !chapaVideoCallPayment.loading && Object.keys(chapaVideoCallPayment.data).length > 0) {
      window.location.href = chapaVideoCallPayment.data.checkout_url;
    }
  }, [chapaVideoCallPayment]);

  const checkoutPayment = (e, video_call_id, amount, index) => {
    if (amount > 0) {
      setActiveIndex(index);
      props.dispatch(
        videoCallsGenrateStripePaymentStart({
          video_call_id: video_call_id,
          amount: amount,
        }));
      setVideoCallId(video_call_id)
      setPaymentModal(true)
      setAmount(amount)
    } else {
      props.dispatch(
        videoCallsPaymentStart({
          video_call_id: video_call_id,
          amount: amount
        })
      );
    }
  }

  useEffect(() => {
    if (!skipRender && !props.videoCallsPayment.loading &&
      Object.keys(props.videoCallsPayment.data).length > 0) {
      props.dispatch(fetchVideocallUserStart());
      window.scrollTo(0, 0);
    }
    setSkipRender(false);
  }, [props.videoCallsPayment]);


  const closePaymentModal = () => {
    setPaymentModal(false);
    setActiveIndex(null)
  };

  const onCopy = (event) => {
    const notificationMessage = getSuccessNotificationMessage(
      "Ticket Copied!!"
    );
    props.dispatch(createNotification(notificationMessage));
  };

  const updateStatus = (videoCallUniqueId, status) => {
    props.dispatch(statusVideocallModelStart({
      video_call_unique_id: videoCallUniqueId,
      status: status,
    }));
  }

  const rejectConfirmation = (videoCallUniqueId, status) => {
    confirmAlert({
      message: t("do_you_want_to_reject_video_call"),
      buttons: [
        {
          label: t("yes"),
          onClick: () => updateStatus(videoCallUniqueId, status)
        },
        {
          label: t("no"),
        }
      ]
    });
  };

  const updateStatusByUser = (videoCallUniqueId, status) => {
    props.dispatch(statusVideocallUserStart({
      video_call_unique_id: videoCallUniqueId,
      status: status,
    }));
  }

  const cancelConfirmation = (videoCallUniqueId, status) => {
    confirmAlert({
      message: t("do_you_want_to_cancel_video_call"),
      buttons: [
        {
          label: t("yes"),
          onClick: () => updateStatusByUser(videoCallUniqueId, status)
        },
        {
          label: t("no"),
        }
      ]
    });
  };


  const startCall = (videoCallId) => {
    props.dispatch(
      startVideocallStart({
        video_call_id: videoCallId,
      })
    );
    // hisotry.push(`/live-streaming/${videoCallUniqueId}`);
  }
  const closeJoinVideoCallModal = () => {
    setJoinVideoCall(false);
  };

  return (
    <>
      <div className="custom-table-card">
        <p>
          <img src={props.isSent ? videoCall.model.picture : videoCall.user.picture} className="table-user-image" />
        </p>
        <p className='link-white'>{props.isSent ?
          <Link to={`/view/${videoCall.model.username}`} className="link-white" title={videoCall.model.name}>{videoCall.model.name}</Link>
          : <div className="link-white" title={videoCall.user.name}>{videoCall.user.name}</div>
        }</p>
        <p>{videoCall.schedule_time_formatted}</p>
        {props.isSent ? <p>
          <div className="ticket-coupon-card">
            {videoCall.ticket}
            <span>
              <CopyToClipboard text={videoCall.ticket} onCopy={onCopy}>
                <Image
                  src={
                    window.location.origin + "/assets/images/live-events/copy.svg"
                  }
                  alt="copy-icon"
                  className="copy-icon"
                />
              </CopyToClipboard>
            </span>
          </div>
        </p> : null
        }
        <p>
          {props.isSent ? videoCall.model.video_call_amount_formatted : videoCall.user.video_call_amount_formatted}
        </p>
        <p>
          <div className={videoCall.status === 0 ? "text-warning" :
            videoCall.status === 1 || videoCall.status === 6 ? "text-success" :
              videoCall.status === 2 || videoCall.status === 4 || videoCall.status === 5 ? "text-danger" : ""}
          >{videoCall.status_formatted}</div>
        </p>
        <p className='action-button'>
          {props.isAction &&
            <>
              {videoCall.is_expired !== 1 ?
                props.isSent ? (
                  <>
                    {videoCall.status === 0 || videoCall.status === 1 ?
                      <>
                        {videoCall.status == 1 && videoCall.is_paid == 0 ?
                          <Button className="completed-btn mr-1 mb-1"
                            onClick={(e) =>
                              checkoutPayment(e, videoCall.video_call_id, videoCall.model.video_call_amount, index)}
                            disabled={activeIndex === index && props.videoCallsGenerateStripe.buttonDisable}
                          >
                            {activeIndex === index && props.videoCallsGenerateStripe.loadingButtonContent !== null
                              ? props.videoCallsGenerateStripe.loadingButtonContent
                              : t("pay_now")}
                          </Button>
                          : null
                        }
                        <Button className="cancel-btn mr-1 mb-1"
                          onClick={() => cancelConfirmation(videoCall.video_call_unique_id, 4)}
                          disabled={props.userVideoCallStatus.buttonDisable &&
                            props.userVideoCallStatus.data.video_call_unique_id == videoCall.video_call_unique_id
                            || props.videoCallPaymentByStripe.buttonDisable &&
                            props.videoCallPaymentByStripe.data.video_call_id == videoCall.video_call_id
                          }
                        >{props.userVideoCallStatus.loading &&
                          props.userVideoCallStatus.data.video_call_unique_id == videoCall.video_call_unique_id ?
                          props.userVideoCallStatus.buttonContent
                          : t("cancel")}</Button>
                      </>
                      : videoCall.status === 8 ?
                        <Button className="cancel-btn mr-1 mb-1"
                          onClick={() => cancelConfirmation(videoCall.video_call_unique_id, 4)}
                          disabled={props.userVideoCallStatus.buttonDisable &&
                            props.userVideoCallStatus.data.video_call_unique_id == videoCall.video_call_unique_id
                            || props.videoCallPaymentByStripe.buttonDisable &&
                            props.videoCallPaymentByStripe.data.video_call_id == videoCall.video_call_id
                          }
                        >{props.userVideoCallStatus.loading &&
                          props.userVideoCallStatus.data.video_call_unique_id == videoCall.video_call_unique_id ?
                          props.userVideoCallStatus.buttonContent
                          : t("cancel")}</Button>
                        : videoCall.status === 6 ?
                          <Button className="completed-btn mr-1 mb-1"
                            onClick={() => setJoinVideoCall(true)}
                          >{joinVideoCall ? t("loading") : t("join")}</Button>
                          : null
                    }
                  </>
                ) :
                  <>
                    {videoCall.status === 0 ? <>
                      <Button className="cancel-btn mr-1 mb-1"
                        onClick={() => rejectConfirmation(videoCall.video_call_unique_id, 2)}
                        disabled={props.modelVideoCallStatus.buttonDisable &&
                          props.modelVideoCallStatus.data.video_call_unique_id == videoCall.video_call_unique_id}
                      >
                        {props.modelVideoCallStatus.buttonDisable &&
                          props.modelVideoCallStatus.data.video_call_unique_id == videoCall.video_call_unique_id &&
                          props.modelVideoCallStatus.data.status === 2 ?
                          props.modelVideoCallStatus.buttonContent
                          : t("reject")}
                      </Button>
                      <Button className="completed-btn mr-1 mb-1"
                        onClick={() => updateStatus(videoCall.video_call_unique_id, 1)}
                        disabled={props.modelVideoCallStatus.buttonDisable &&
                          props.modelVideoCallStatus.data.video_call_unique_id == videoCall.video_call_unique_id}
                      >
                        {props.modelVideoCallStatus.buttonDisable &&
                          props.modelVideoCallStatus.data.video_call_unique_id == videoCall.video_call_unique_id &&
                          props.modelVideoCallStatus.data.status === 1 ?
                          props.modelVideoCallStatus.buttonContent
                          : t("accept")}
                      </Button>
                    </>
                      : videoCall.status === 8 ? <>
                        <Button className="completed-btn mr-1 mb-1"
                          onClick={() => startCall(videoCall.video_call_id)}
                        >{t("start_call")}</Button>
                      </>
                        : videoCall.status === 6 ? <>
                          <Button className="completed-btn mr-1 mb-1"
                            onClick={() => startCall(videoCall.video_call_id)}
                          >Join</Button>
                          <Button className="cancel-btn mr-1 mb-1"
                            onClick={() => props.dispatch(endVideocallStart({
                              video_call_id: videoCall.video_call_id,
                            }))}
                            disable={
                              props.endVideoCall.buttonDisable &&
                              props.endVideoCall.data.video_call_id === videoCall.video_call_id
                            }
                          >
                            {props.endVideoCall.buttonDisable &&
                              props.endVideoCall.data.video_call_id === videoCall.video_call_id ?
                              props.endVideoCall.buttonContent
                              : t("end_call")}
                          </Button>
                        </>
                          : null
                    }
                  </>
                : null
              }
            </>
          }
          <Button className="declined-btn mr-1 mb-1"
            onClick={() => props.setSelectedVideoCall(videoCall)}
          >{t("view")}</Button>
        </p>
      </div>
      {joinVideoCall && <JoinVideoCallModal
        joinVideoCall={joinVideoCall}
        closeJoinVideoCallModal={closeJoinVideoCallModal}
        videoCall={videoCall}
      />
      }
      {paymentModal &&
        <PaymentModal
          clientSecret={clientSecret}
          closePaymentModal={closePaymentModal}
          videoCallId={videoCallId}
          handleChapaPayment={handleChapaVideoCallPayment}
          amount={amount}

        />
      }
    </>
  );
}

const mapStateToPros = (state) => ({
  userVideoCallStatus: state.videocall.userVideoCallStatus,
  modelVideoCallStatus: state.videocall.modelVideoCallStatus,
  videoCallPaymentByStripe: state.videocall.videoCallPaymentByStripe,
  endVideoCall: state.videocall.endVideoCall,
  videoCallsGenerateStripe: state.videocall.videoCallsGenerateStripe,
  videoCallsPayment: state.videocall.videoCallsPayment,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(withTranslation(MyTicketsTableRow));