import React, { useState, useEffect } from 'react';
import { Form, Modal, Col, Row, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import PaymentMethodCard from '../RequestBooking/PaymentMethodCard';
import ChapaPayment from '../RequestBooking/ChapaPayment';
import { useSelector, useDispatch } from 'react-redux';
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import VideoCallPaymentSec from "./VideoCallPaymentSec";
import configuration from "react-global-configuration";
import { t, withTranslation } from "react-multi-lang";

const PaymentModal = (props) => {
    const [paymentType, setPaymentType] = useState("CHAPA");

    const stripePromise = loadStripe(
        configuration.get("configData.stripe_publishable_key")
    );

    const appearance = {
        theme: 'night'
    };

    const options = {
        clientSecret: props.clientSecret,
        appearance: appearance,
    };

    return (
        <>
            <Modal
                className="modal-dialog-center payment-add-card-modal"
                size="lg"
                centered
                show={true}
                onHide={props.closePaymentModal}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t("add_card")}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="video-payment-sec">
                    <div className="payment-modal-body">
                        <Row className="justify-content-between  align-items-center">
                            <PaymentMethodCard
                                paymentType={paymentType}
                                setPaymentType={setPaymentType}
                            />
                            <Col md={12} xl={6}>
                                {!props.videoCallsGenerateStripe.loading && props.clientSecret && paymentType == "CARD" ?
                                    <Elements options={options} stripe={stripePromise}>
                                        <VideoCallPaymentSec
                                            videoCallId={props.videoCallId}
                                            clientSecret={props.clientSecret}
                                            closePaymentModal={props.closePaymentModal}
                                        />
                                    </Elements> : <ChapaPayment
                                        amount={props.amount}
                                        handleSubmit={props.handleChapaPayment}
                                    />
                                }
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal >
        </>
    );

}


const mapStateToPros = (state) => ({
    videoCallsGenerateStripe: state.videocall.videoCallsGenerateStripe,
});

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(
    mapStateToPros,
    mapDispatchToProps
)(withTranslation(PaymentModal));