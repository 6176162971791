import React from "react";
import { VideoPlayer, VideoPlayerProps } from "@graphland/react-video-player";
function AdVideo({ url }) {
  const videoSources = [
    {
      src: url,
      type: "video/mp4",
    },
    // Add more video sources as needed
  ];
  const videoProps = {
    theme: "city",
    height: 240,
    width: 430,
    autoPlay: true,
    loop: false,
    sources: videoSources,
    controlBar: {
      skipButtons: {
        forward: 5,
        backward: 5,
      },
    },
    playbackRates: [0.5, 1, 1.5, 2],
    disablePictureInPicture: false,
    onReady: () => {},
  };

  return (
    <>
      <video src={url} className="container" controls muted></video>
    </>
  );
}

export default AdVideo;
