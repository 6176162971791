import React, { useState, useEffect, Component } from "react";
import { connect } from "react-redux";
import "./Home.css";
import PhoneoHomeBanner from "./PhoneoHomeBanner";
import HomeFeatured from "./HomeFeatured";
import HomeCategories from "./HomeCategories";
import HomeBlog from "./HomeBlog";
import { fetchCategoryStart } from "../../store/actions/LookUpAction";
import {
  featuredModelsStart,
  fetchHomeStart,
} from "../../store/actions/ModelAction";
import CategoryModelSec from "./CategoryModelSec";
import HomeCategory from "./HomeCategory";
import Ads from "./Ads";

const HomeIndex = (props) => {
  useEffect(() => {
    if (props.category.loading) props.dispatch(fetchCategoryStart());
    if (props.home.loading) props.dispatch(fetchHomeStart());
    if (props.featuredModel.loading) props.dispatch(featuredModelsStart());
  }, []);
  return (
    <>
      <div className="main-wrapper">
        <PhoneoHomeBanner />
        <HomeFeatured featuredModel={props.featuredModel} />
        {/* <HomeCategories category={props.category} /> */}
        <HomeCategory category={props.category} type="category" />
        <Ads />
        <CategoryModelSec home={props.home} />
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  category: state.lookup.category,
  home: state.model.home,
  featuredModel: state.model.featuredModel,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(HomeIndex);
