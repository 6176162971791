
import React, { useState, useEffect, Component, useRef } from "react";
import { connect } from "react-redux";
import "./RequestBookingIndex.css";
import RequestBookingCard from "./RequestBookingCard";
import { fetchSingleModelStart } from "../../store/actions/ModelAction";
import { fetchSingleSubscriptionStart } from "../../store/actions/SubscriptionAction";
import { fetchOccasionStart } from "../../store/actions/LookUpAction";
import { bookingSaveStart } from "../../store/actions/BookingAction";
import configuration from "react-global-configuration";
import { Link, useHistory } from "react-router-dom";
import { t, withTranslation } from "react-multi-lang";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { generateStripeStart, chapaSubscriptionPaymentStart } from "../../store/actions/WalletAction";
import StripePaymentSec from "./StripePaymentSec";
import Skeleton from "react-loading-skeleton";
import PaymentMethodCard from "./PaymentMethodCard";
import {
  Row,
  Col,
  Form,
} from "react-bootstrap";
import ChapaPayment from "./ChapaPayment";
import { useSelector, useDispatch } from "react-redux";

const RequestBookingIndex = (props) => {
  const dispatch = useDispatch();
  const chapaSubscriptionPayment = useSelector(state => state.wallet.chapaSubscriptionPayment)
  const [paymentType, setPaymentType] = useState("CHAPA");
  const [inComingData, setInComingData] = useState(false);
  const [skipRender, setSkipRender] = useState(true);
  const [amount, setAmount] = useState(0);
  const [clientSecret, setClientSecret] = useState("");
  const [paymentSection, setPaymentSection] = useState(false);
  const [inputData, setInputData] = useState({
    user_subscription_id: props.match.params.subscription_id,
    model_username: props.match.params.username,
  });

  const mystyle = {
    color: "white"
  };

  const stripePromise = loadStripe(
    configuration.get("configData.stripe_publishable_key")
  );

  const appearance = {
    theme: 'night'
  };

  const options = {
    clientSecret,
    appearance,
  };


  const history = useHistory();

  const routeChange = () => {
    history.goBack()
  };


  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const prevParams = usePrevious(props.match.params.username);

  useEffect(() => {
    props.dispatch(fetchOccasionStart());
  }, []);

  useEffect(() => {
    props.dispatch(
      fetchSingleSubscriptionStart({
        user_subscription_id: props.match.params.subscription_id,
      })
    );
    if (
      props.location.model !== undefined &&
      props.location.subscription !== undefined
    ) {
      setInComingData(true);
    } else {
      if (prevParams !== props.match.params.username) {
        props.dispatch(
          fetchSingleModelStart({
            username: props.match.params.username,
          })
        );
      }
    }
  }, [prevParams, props.match.params.username]);

  useEffect(() => {
    if (!skipRender && !props.singleSubscription.loading &&
      Object.keys(props.singleSubscription.data).length > 0) {
      setAmount(props.singleSubscription.data.subscription.amount);
    }
    setSkipRender(false);
  }, [props.singleSubscription]);


  const handleSubmit = (event) => {
    event.preventDefault();
    props.dispatch(generateStripeStart({ amount: amount }));
  };

  const handleChapaSubscriptionPayment = (event) => {
    event.preventDefault();
    dispatch(chapaSubscriptionPaymentStart({
      ...inputData,
      model_id: props.singleModel.data.user.user_id,
      amount: amount 
    }))
  }

  useEffect(() => {
    if (!skipRender && !props.generateStripe.loading &&
      Object.keys(props.generateStripe.data).length > 0) {
      setClientSecret(props.generateStripe.data.clientSecret);
      setPaymentSection(true);
    }
  }, [props.generateStripe]);

  useEffect(() => {
    if (!skipRender && !chapaSubscriptionPayment.loading && Object.keys(chapaSubscriptionPayment.data.data).length > 0) {
      window.location.href = chapaSubscriptionPayment.data.data.checkout_url;
    }
  }, [chapaSubscriptionPayment]);

  return (
    <>
      <div className="main-wrapper">
        <div className="request-booking-sec">
          <div className="request-booking-card">
          <Form className="request-bread">
            <span onClick={routeChange}>1. {t("account")}</span>
            <span className={`${!paymentSection ? "active" : ""}`}>2. {t("request")}</span>
            <span className={`${paymentSection ? "active" : ""}`}>3. {t("payment")}</span>
          </Form>
          {!paymentSection ?
            <>
              {props.singleModel.loading ? (
                <div className="request-booking-card">
                  <div className="request-booking-profile-sec">
                    <Skeleton circle={true} count={1} height={80} width={80} />
                    <p className="profile-name">
                      <Skeleton count={1} height={26} width={280} />
                    </p>
                  </div>
                  <div className="request-book-tab-sec">
                    <p className="sub-title"> <Skeleton count={1} height={26} width={130} /></p>
                    <div className="radio-nav-tab-list-sec mb-3">
                      <div className="request-felx">
                        <Skeleton circle={true} count={1} height={92} width={92} />
                      </div>
                      <div className="request-felx">
                        <Skeleton circle={true} count={1} height={92} width={92} />
                      </div>
                      <div className="request-felx">
                        <Skeleton circle={true} count={1} height={92} width={92} />
                      </div>
                    </div>
                    <div className="request-book-tab-content-sec">
                      <Skeleton className="mb-3" count={3} height={40} width={560} />
                    </div>
                  </div>
                </div>
              ) : (
                <RequestBookingCard
                  inputData={inputData}
                  setInputData={setInputData}
                  singleModel={props.singleModel}
                  occasion={props.occasion}
                  handleSubmit={handleSubmit}
                  saveBooking={props.saveBooking}
                  generateStripe={props.generateStripe}
                />
              )
              }
              < small className="request-booking-bottom-text">
                {t("by_booking_you_agree_to")} {configuration.get("configData.site_name")}
                's
                <a href="/page/terms" target="_blank">
                  {t("terms_service")}
                </a>
                <span>
                  {" "}
                  {t("and")}
                  <a href="/page/privacy" target="_blank">
                    {t("privacy_policy")}
                  </a>
                </span>
              </small>
            </>
            :
            <div className="payment-modal-body">
              <Row className="justify-content-between g-3 align-items-center">
                <PaymentMethodCard
                  paymentType={paymentType}
                  setPaymentType={setPaymentType}
                />
                <Col md={12} xl={6}>
                  {!props.generateStripe.loading && clientSecret && props.generateStripe.data.amount == amount && paymentType == "CARD" ?
                    <Elements options={options} stripe={stripePromise}>
                      <StripePaymentSec
                        inputData={inputData}
                        model_id={props.singleModel.data.user.user_id}
                        clientSecret={clientSecret}
                      />
                    </Elements> : <ChapaPayment
                      amount={amount}
                      handleSubmit={handleChapaSubscriptionPayment}
                    />
                  }
                </Col>
              </Row>
            </div>
          }
        </div>
        </div>
      </div >
    </>
  );
};

const mapStateToPros = (state) => ({
  singleModel: state.model.singleModel,
  occasion: state.lookup.occasion,
  saveBooking: state.bookings.saveBooking,
  singleSubscription: state.subscriptions.singleSubscription,
  generateStripe: state.wallet.generateStripe,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(withTranslation(RequestBookingIndex));