import React, { useState, useEffect, useLayoutEffect } from "react";
import { connect } from "react-redux";
import configuration from "react-global-configuration";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { t, withTranslation } from "react-multi-lang";
import "./Auth.css";
import {
  userLoginStart,
  userRegisterStart,
} from "../../store/actions/UserAction";
import SocialButton from "../Helper/SocialButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { gapi } from "gapi-script";
const eye = <FontAwesomeIcon icon={faEye} />;
const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} />;

const $ = window.$;

const LoginIndex = (props) => {
  const [loginInputData, setLoginInputData] = useState({});

  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  useEffect(() => {
    gapi.load("client:auth2", () => {
      gapi.client.init({
        clientId: configuration.get("configData.GOOGLE_CLIENT_ID"),
        plugin_name: "chat",
      });
    });
  }, []);

  const handleLogin = (event) => {
    event.preventDefault();
    props.dispatch(userLoginStart(loginInputData));
  };

  const handleFacebookLogin = (user) => {
    console.log("handleFacebookLogin", user._profile);
    props.dispatch(
      userRegisterStart({
        name: user._profile.name,
        first_name: user._profile.firstName ? user._profile.firstName : "",
        last_name: user._profile.lastName ? user._profile.lastName : "",
        email: user._profile.email ? user._profile.email : "",
        social_unique_id: user._profile.id,
        picture: user._profile.profilePicURL,
        login_by: "facebook",
      })
    );
  };

  const handleGoogleLogin = (user) => {
    console.log("handleGoogleLogin", user._profile);
    props.dispatch(
      userRegisterStart({
        name: user._profile.name,
        email: user._profile.email,
        first_name: user._profile.firstName ? user._profile.firstName : "",
        last_name: user._profile.lastName ? user._profile.lastName : "",
        social_unique_id: user._profile.id,
        picture: user._profile.profilePicURL,
        login_by: "google",
      })
    );
  };

  const handleSocialLoginFailure = (err) => {
    console.error(err);
  };

  useEffect(() => { }, []);

  const { pathname } = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <div className="main-wrapper">
        <Container>
          <div className="auth-warpper">
            <Row>
              <Col
                md={{ span: 6, offset: 3 }}
                sm={6}
                lg={{ span: 6, offset: 3 }}
                xl={{ span: 6, offset: 3 }}
              >
                <div className="login-box">
                  <h2>{t("login_to")} {configuration.get("configData.site_name")}</h2>
                  <div className="login-type-sec">
                    {configuration.get("configData.FB_CLIENT_ID") ? (
                      <SocialButton
                        provider="facebook"
                        appId={configuration.get("configData.FB_CLIENT_ID")}
                        onLoginSuccess={handleFacebookLogin}
                        onLoginFailure={handleSocialLoginFailure}
                        className="btn-facebook"
                        id="facebook-connect"
                      >
                        <span>{t("login_with_facebook")}</span>
                      </SocialButton>
                    ) : (
                      ""
                    )}
                    {configuration.get("configData.GOOGLE_CLIENT_ID") ? (
                      <SocialButton
                        provider="google"
                        key={"google"}
                        appId={configuration.get("configData.GOOGLE_CLIENT_ID")}
                        onLoginSuccess={handleGoogleLogin}
                        onLoginFailure={handleSocialLoginFailure}
                        className="btn-apple"
                        id="google-connect"
                      >
                        <span>{t("login_with_google")}</span>
                      </SocialButton>
                    ) : (
                      ""
                    )}
                  </div>

                  {configuration.get("configData.GOOGLE_CLIENT_ID") ||
                    configuration.get("configData.FB_CLIENT_ID") ? (
                    <div className="or-sec">
                      <span className="or-text">{t("or")}</span>
                    </div>
                  ) : (
                    <span classsName="login-or-hide"></span>
                  )}

                  <Form className="login-form" onSubmit={handleLogin}>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>{t("email")}</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder={t("you@email.com")}
                        value={loginInputData.email}
                        name="email"
                        onChange={(event) =>
                          setLoginInputData({
                            ...loginInputData,
                            email: event.currentTarget.value,
                          })
                        }
                      />
                    </Form.Group>
                    <Form.Group controlId="formBasicPassword">
                      <Form.Label>{t("password")}</Form.Label>
                      <Form.Control
                        type={passwordShown ? "text" : "password"}
                        placeholder={t("password")}
                        value={loginInputData.password}
                        name="password"
                        onChange={(event) =>
                          setLoginInputData({
                            ...loginInputData,
                            password: event.currentTarget.value,
                          })
                        }
                      />
                      <i
                        className="toggle-password"
                        onClick={togglePasswordVisiblity}
                      >
                        {passwordShown ?
                          <span className="passowrd-eye">
                            {eyeSlash}
                          </span> :
                          <span className="passowrd-eye">
                            {eye}
                          </span>

                        }
                      </i>{" "}
                    </Form.Group>
                    <Button
                      className="btn-email"
                      // onClick={handleLogin}
                      type="submit"
                      disabled={props.login.buttonDisable}
                    >
                      {props.login.loadingButtonContent !== null
                        ? props.login.loadingButtonContent
                        : t("login")}
                    </Button>
                  </Form>
                  <div className="login-footer-main-sec">
                    <span className="login-desc-text">
                      {t("signing_up_you_agree")} {" "}
                      {configuration.get("configData.site_name")}'s
                      <Link to={"/page/terms"} className="txt-underline">
                        {" "}
                        {t("terms_service")}
                      </Link>
                      , {t("including")}
                      <Link to={"/page/terms"} className="txt-underline">
                        {" "}
                        {t("additional_terms")}
                      </Link>
                      , and
                      <Link to={"/page/privacy"} className="txt-underline">
                        {" "}
                        {t("privacy_policy")}
                      </Link>
                    </span>
                  </div>
                  <div className="login-footer-sec">
                    <span className="login-desc-text">
                      <Link to={"/forgot-password"}> {t("forgot_your_password")}</Link>
                    </span>
                  </div>
                  <div className="login-footer-sub-sec">
                    <span className="login-desc-text">
                      {t("dont_have_an_account")}
                      <Link to={"/signup"}>{t("signup")}</Link>
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  login: state.users.loginInputData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

// export default connect(mapStateToPros, mapDispatchToProps)(LoginIndex);


export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(LoginIndex));