import React from "react";
import {
  Col,
} from "react-bootstrap";
import { withTranslation, t } from "react-multi-lang";

const PaymentMethodCard = (props) => {

  const changePaymentType = (type, card = null) => {
    props.setPaymentType(type);
  };

  return (
    <Col md={12} xl={6}>
      <div className="payment-method-sec">
        <h4 className="payment-modal-title">{t("payment_methods")}</h4>
        <div className="wallet-grid-card">
          <label
            className="custome-check-card"
            onClick={() => changePaymentType("CHAPA")}
          >
            <div className="custome-inner-card d-flex justify-content-between align-items-center">
              <h5 className="wallet-payment-title ">Chapa</h5>
              <input
              name="plan"
              className="radio"
              type="radio"
              checked={props.paymentType === "CHAPA"}
            />
            </div>
          </label>
          <label
            className="custome-check-card"
            onClick={() => changePaymentType("CARD")}
          >
            <div className="custome-inner-card d-flex justify-content-between align-items-center">
              <h5 className="wallet-payment-title ">Card</h5>
              <input
              name="plan"
              className="radio"
              type="radio"
              checked={props.paymentType === "CARD"}
            />
            </div>
          </label>
        </div>
      </div>
    </Col>
  );
};



export default
  (withTranslation(PaymentMethodCard));
